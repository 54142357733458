.maintenance-wrapper {
    height: 80vh;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.maintenance__icon {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-bottom: 12px;
}
