@import '../styles/config';
@import '../styles/includes';

.ace-c-driver-assignment-archive-view {
    margin-top: 82px;
    margin-bottom: 84px;
    position: relative;

    @include responsive-desktop-up() {
        margin-top: 102px;
    }

    @include responsive-desktop-xxl-up() {
        margin-top: 150px;
    }
}

.ace-c-driver-assignment-archive-view__header {
    margin-bottom: 90px;
}

.ace-c-driver-assignment-archive-view__table-header {
    padding-top: 40px;
    padding-bottom: 13px;
    margin-bottom: 5px;
    position: sticky;
    z-index: 3;
    background: map-get($color, ace-white);
    top: 70px;
    width: 100%;
}
