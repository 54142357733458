@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-text-area__wrapper {
    position: relative;
}

.ace-c-text-area {
    @extend %ace-input-main-font;

    border-radius: 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid $border map-get($color, ace-grey-medium);
    background-color: map-get($color, ace-white);
    padding: 16px 28px;
    display: flex;
    align-items: center;
    width: 100%;
    color: map-get($color, ace-bodycopies);

    &:focus {
        border: solid $border map-get($color, ace-bodycopies);
    }

    &--locked {
        pointer-events: none;
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    &--error {
        border: solid $border map-get($color, ace-red);
    }

    &--disabled {
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    @include responsive-desktop-wide-up() {
        padding: 14px 28px;
    }
}

.ace-c-text-area__label {
    @extend %ace-input-main-font;

    position: absolute;
    top: 18px;
    left: 29px;
    color: map-get($color, ace-grey-dark);
    transition: all 0.3s;

    @include responsive-desktop-wide-up() {
        top: 16px;
    }

    &--focused {
        @extend %ace-input-placeholder-font;
        @extend %ace-input-placeholder-position;

        left: 29px;
        color: map-get($color, ace-grey-medium);
    }

    &--locked {
        color: map-get($color, ace-grey-dark);
    }
}

.ace-c-text-area--focused ~ .ace-c-text-area__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    left: 29px;
    color: map-get($color, ace-grey-medium);
}

.ace-c-text-area--additional-services {
    overflow: hidden;
}
