@import '../styles/config';
@import '../styles/includes';

.ace-app-layout-view {
    display: flex;
    flex-direction: column;

    header {
        position: fixed;
        width: 100vw;
        z-index: 150;
    }
}

.ace-app-layout-view__content {
    padding-top: map-get($app-layout, header-height);
    min-height:
        calc(
            100vh
            - #{map-get($app-layout, header-height)}
            - #{map-get($app-layout, footer-height)}
        );
}

.ace-app-layout-view__footer {
    @extend %ace-copy-s;

    text-align: center;
    padding: 1rem;
    height: map-get($app-layout, footer-height);
}

.will-be-implemented-header {
    display: inline-block;

    &::after {
        content: 'Bald verfügbar';
        position: absolute;
        font-size: 11px;
        color: white;
        background-color: red;
        padding: 0 2px;
    }
}

.will-be-implemented {
    display: flex;

    &::after {
        content: 'Bald verfügbar';
        position: absolute;
        font-size: 11px;
        color: white;
        background-color: red;
        padding: 0 2px;
        top: 3px;
        left: -30px;
        min-width: 80px;
    }
}

.will-be-implemented-icons {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    &::after {
        content: 'Bald verfügbar';
        position: absolute;
        font-size: 11px;
        color: white;
        background-color: red;
        padding: 0 2px;
        top: 3px;
        right: 90px;
        transform: translateX(100%);
        min-width: 71px;
    }
}

.will-be-implemented-search {
    display: flex;

    &::after {
        content: 'Bald verfügbar';
        position: absolute;
        font-size: 11px;
        color: white;
        background-color: red;
        padding: 0 2px;
        top: -29px;
        right: 63px;
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        min-width: 81px;
        height: 14px;
        line-height: 14px;
    }
}
