@import '../../../styles/config';
@import '../../../styles/mixins';
@import '../../../styles/includes';
@import '../../../styles/grid-variables';

.ace-driver-assignment-archive-row-detail__table-body {
    background-color: map-get($color, ace-grey-background);
}

.ace-driver-assignment-archive-row-detail__table-row {
    grid-template-rows: repeat(4, auto);

    &--border {
        border-top: 1px solid rgba(84, 97, 90, 0.1);
    }
}

.ace-driver-assignment-archive-row-detail__cell {
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative;

    &--light {
        background-color: map-get($color, ace-grey-superlight);
    }
}

.ace-driver-assignment-archive-row-detail__time-cell {
    display: block;
}

.ace-driver-assignment-archive-row-detail__time-text {
    @extend %ace-copy-s;

    margin-bottom: 8px;
}

.ace-driver-assignment-archive-row-detail__flex-start {
    align-items: flex-start;
}

.ace-driver-assignment-archive-row-detail__time-text-title {
    width: 60px;
    display: inline-block;
}
