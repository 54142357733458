@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';

.vehicle-form__radio-button-wrapper {
    margin-top: 50px;
    margin-bottom: 16px;
}

.vehicle-form__radio-button-container {
    position: relative;
    text-transform: lowercase !important;
}

.vehicle-form__label {
    @extend %ace-copy-m;

    margin-bottom: 16px;
}

.vehicle-form__buttons-container {
    margin-top: 70px;
}

.vehicle-form__button {
    width: 200px;
    height: 54px;
    border-radius: 27px;
    font-size: 2.1rem;
    margin: 0 15px;
    cursor: pointer;
    background-color: #d8d8d8;
    color: rgba(0, 0, 0, 0.5);

    &--is-green {
        background-color: #00d24d;
        color: white;
    }
}

.vehicle-form__toast-message {
    width: 540px;
    position: absolute;
    bottom: 76px;
    left: 0;
}
