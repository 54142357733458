@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-c-invoice-submission-panel__table-header {
    padding-bottom: 13px;
    margin-bottom: 5px;
    position: sticky;
    z-index: 2;
    background: map-get($color, ace-white);
    top: 179px;

    @include responsive-desktop-up() {
        top: 199px;
    }

    @include responsive-desktop-wide-up() {
        top: 199px;
    }

    @include responsive-desktop-xxl-up() {
        padding-top: 18px;
        padding-bottom: 29px;
        top: 278px;
    }
}
