@import '../../styles/config';
@import '../../styles/mixins';

.ace-c-pop-up {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    align-items: center;

    &.ace-is-error {
        z-index: 10000000;
    }
}

.ace-c-pop-up__wrapper {
    width: 100%;
    position: relative;
}

.ace-c-pop-up__background-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    pointer-events: none;
    user-select: none;
}

.ace-c-pop-up__background-container {
    background-color: map-get($color, ace-white);
    height: 100%;
    pointer-events: none;
}

.ace-c-pop-up__content-container {
    max-height: 80vh;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.ace-c-pop-up__button {
    @include flex-center();

    position: absolute;
    background-color: map-get($color, ace-white);
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
}
