@import '../../styles/config';
@import '../../styles/includes';

.ace-c-tooltip {
    position: relative;
}

.ace-c-tooltip__message {
    @extend %ace-copy-m;

    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 382px;
    border-radius: 30px;
    padding: 16px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    z-index: 2;

    &--right {
        right: 40px;
    }

    &--left {
        left: 40px;
    }

    &--top-left {
        transform: none;
        white-space: nowrap;
        top: -50%;
        left: 100%;
        border-radius: 8px;
        padding: 5px;
        width: unset;
    }

    &--top {
        @extend %ace-copy-s;

        overflow-wrap: anywhere;
        display: none;
        position: absolute;
        top: unset;
        transform: unset;
        border-radius: 8px;
        bottom: 110%;
        left: 0;
        padding: 5px;
        width: unset;
    }
}

.ace-c-tooltip__icon {
    &:hover {
        cursor: help;
    }

    &:hover + .ace-c-tooltip__message {
        display: inline-block;
    }
}
