@import '../../styles/config';
@import '../../styles/includes';

.ace-c-toggle {
    position: relative;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;

    &--disabled {
        pointer-events: none;
        cursor: none;
    }
}

.ace-c-toggle__button {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.15);
    background-color: map-get($color, ace-white);
}

.ace-c-toggle__button input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ace-c-toggle__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.3s;
    border-radius: 34px;
    border: solid 2px map-get($color, ace-grey-medium);
}

.ace-c-toggle__slider::before {
    position: absolute;
    content: '';
    height: 30px;
    width: 30px;
    left: 3px;
    bottom: 3px;
    background-color: map-get($color, ace-grey-medium);
    transition: 0.3s;
    border-radius: 34px;
}

input:checked + .ace-c-toggle__slider::before {
    transform: translateX(40px);
    background-color: map-get($color, ace-red);
}

input:checked + .ace-c-toggle__slider {
    border: solid 2px map-get($color, ace-red);
}

.ace-c-toggle__label {
    @extend %ace-copy-s;

    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(40px + 8px);
    width: 160px;
}
