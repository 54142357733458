@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-list-option {
    @extend %ace-input-main-font;

    height: 42px;
    padding-left: 27px;
    padding-right: 27px;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    color: map-get($color, ace-bodycopies);
    cursor: pointer;

    &:nth-child(odd) {
        background-color: map-get($color, ace-grey-superlight);
    }

    &:hover {
        &::after {
            content: '';
            width: 5px;
            border-radius: 2.5px;
            background-color: map-get($color, ace-red);
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &--font-md {
        @extend %ace-input-main-font-md;
    }

    @include responsive-desktop-up() {
        height: 48px;
    }

    @include responsive-desktop-wide-up() {
        height: 54px;
    }

    @include responsive-desktop-xxl-up() {
        height: 56px;
    }
}
