@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-document-download-view__header {
    display: none;
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;
}

.ace-document-download-view__button {
    margin-top: 50px;
    margin-bottom: 50px;
}

.ace-document-download-view__directory-table-row {
    &:hover {
        box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
        z-index: 1;
    }
}

.ace-document-download-view__directory-arrow {
    &.icon-rotate {
        transform: rotate(180deg);
    }
}

.ace-document-download-view__file-table-row-wrapper {
    &:nth-of-type(even) {
        background-color: map-get($color, ace-grey-light);
    }

    &:nth-of-type(odd) {
        background-color: map-get($color, ace-grey-row);
    }
}

.ace-document-download-view__file-table-row {
    height: 85px;
    cursor: pointer;
}
