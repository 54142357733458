@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-radio-button {
    @include flex-center();

    @extend %ace-input-main-font;

    font-weight: bold;
    height: 60px;
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid 2px map-get($color, ace-red);
    background-color: map-get($color, ace-white);
    color: map-get($color, ace-red);
    cursor: pointer;
    opacity: 0.5;
    text-transform: uppercase;

    &--selected {
        background-color: map-get($color, ace-red);
        color: map-get($color, ace-white);
        opacity: 1;
    }

    &--is-disabled.ace-radio-button--selected {
        pointer-events: none;
        background-color: map-get($color, ace-grey-medium);
        color: map-get($color, ace-white);
        box-shadow: none;
    }

    &--is-disabled {
        pointer-events: none;
        color: map-get($color, ace-grey-medium);
        border: solid 2px map-get($color, ace-grey-medium);
        box-shadow: none;
    }

    &--icon-container {
        margin-left: -40px;
        margin-right: 20px;
        margin-top: 2px;
        display: flex;
    }

    &:active {
        background-color: map-get($color, ace-red-dark);
        color: #fff;
        opacity: 1;
    }
}
