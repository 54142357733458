/* Global styling */
@import '~normalize.css';
@import './reset';
@import './box-sizing';
@import './config';
@import './css-grid';

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', sans-serif;
}

svg {
    width: 100%;
    height: 100%;
}
