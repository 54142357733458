@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-case-state-pill {
    @include flex-center();

    @extend %ace-copy-s;

    line-height: 19px;
    font-size: 14px;
    height: 30px;
    border-radius: 15px;
    font-weight: bold;
    user-select: none;
    width: 136px;

    @include responsive-desktop-up() {
        width: 160px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 16px;
        line-height: 21px;

        &--font-14 {
            font-size: 14px;
        }
    }

    &--red {
        background-color: map-get($color, ace-red);
        color: map-get($color, ace-white);
        border: solid 2px map-get($color, ace-red);
    }

    &--grey {
        background-color: map-get($color, ace-grey-dark);
        color: map-get($color, ace-white);
        border: solid 2px map-get($color, ace-grey-dark);
    }

    &--trans-red {
        background-color: transparent;
        color: map-get($color, ace-red);
        border: solid 2px map-get($color, ace-red);
    }

    &--trans-grey {
        background-color: transparent;
        color: map-get($color, ace-grey-medium);
        border: solid 2px map-get($color, ace-grey-medium);
    }
}
