@import '../../../styles/config';
@import '../../../styles/functions';
@import '../../../styles/mixins';
@import '../../../styles/includes';

.ace-c-table-header-cell {
    align-self: center;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.ace-c-table-header-cell__text {
    user-select: none;
    color: map-get($color, ace-grey-dark);
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    display: inline-block;
    font-weight: 300;

    @include responsive-desktop-wide-up() {
        font-size: 18px;
        line-height: 24px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 20px;
        line-height: 27px;
    }
}
