@import '../../styles/config';
@import '../../styles/includes';

.ace-c-warning-message {
    border-radius: 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: map-get($color, ace-white);
    align-items: center;
    z-index: 3;
    display: none;
    text-transform: none;

    > .ace-c-icon {
        flex: 0 0 50px;
        margin-right: 10px;
    }

    &--show {
        display: flex;
    }

    &--full-width {
        display: flex;
        flex: 1;
    }
}
