@import '../../styles/config';

.ace-c-ribbon {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &--red {
        background-color: map-get($color, ace-red);
    }

    &--grey {
        background-color: map-get($color, ace-grey-background);
    }

    &--light-grey {
        background-color: map-get($color, ace-grey-superlight);
    }
}
