@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-manage-users-view__header {
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;
}

.ace-manage-users-view__table-row {
    height: 70px;
}

.ace-c-manage-users-view__link {
    justify-content: flex-end;

    @include responsive-desktop-up() {
        justify-content: flex-start;
    }
}

.ace-manage-users-view__button-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
}

.ace-manage-users-view__table-body {
    > * {
        &:nth-child(odd) {
            background-color: map-get($color, ace-grey-row);
        }
    }
}

.ace-manage-users-view__checkbox-label {
    @extend %ace-input-main-font;

    margin-bottom: 20px;
}
