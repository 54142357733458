@import '../../styles/mixins';
@import '../../styles/includes';

.ace-c-screen-message__wrapper {
    text-align: center;
    margin-top: 40px;

    @include responsive-desktop-up() {
        margin-top: 80px;
    }

    @include responsive-desktop-wide-up() {
        margin-top: 77px;
    }

    @include responsive-desktop-xxl-up() {
        margin-top: 160px;
    }
}

.ace-c-screen-message__paragraph-wrapper {
    > * {
        margin-bottom: 18px;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.ace-c-screen-message__dot {
    &--first {
        color: black;
    }

    &--second {
        color: grey;
    }

    &--third {
        color: lightgray;
    }
}

.loading-dots::after {
    @extend %ace-h4;

    content: '. ';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: black;
        text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow:
            0.25em 0 0 black,
            0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow:
            0.25em 0 0 black,
            0.5em 0 0 black;
    }
}
