@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/mixins';
@import '../../styles/includes';

.search-table-body {
    padding-top: 50px;

    @include responsive-desktop-xxl-up() {
        padding-top: 100px;
    }

    > * {
        cursor: pointer;

        &:hover {
            box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
            z-index: 2;
        }

        &:nth-child(odd) {
            background-color: map-get($color, ace-grey-row);
        }
    }
}
