@import '../../../styles/config';
@import '../../../styles/mixins';
@import '../../../styles/includes';
@import '../../../styles/grid-variables';

.ace-invoice-submission-archive-row__icon {
    line-height: 0;
    margin-left: 7px;
    margin-right: 37px;

    @include responsive-desktop-up() {
        margin-left: 13px;
    }

    @include responsive-desktop-wide-up() {
        margin-left: 13px;
    }

    @include responsive-desktop-xxl-up() {
        margin-right: 42px;
    }

    &--rotate {
        transform: rotate(180deg);
    }
}

.ace-c-table-row__status-column {
    @include flex-start();
}

.ace-c-table__icon-status-wrapper {
    @include flex-center();

    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9f9fa0;
    margin-right: 15px;
}
