@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';

.ace-c-hover-icon {
    cursor: pointer;
    font-size: 0;
    height: 40px;
    width: 40px;
    position: relative;

    .ace-c-hover-icon__text {
        display: none;
    }

    &:hover {
        .ace-c-icon {
            display: none;
        }

        .ace-c-hover-icon__text {
            @extend %ace-copy-s;

            position: absolute;
            font-weight: bold;
            display: block;
            white-space: nowrap;

            &--left {
                top: 50%;
                left: 0%;
                transform: translate(0%, -50%);
            }

            &--center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &--red {
                color: map-get($color, ace-red);
            }

            &--grey {
                color: #9f9fa0;
            }
        }
    }

    &--is-not-visible {
        display: none;
    }

    &--disabled {
        &:hover {
            color: map-get($color, ace-grey-medium);
        }
    }

    &--is-not-clickable {
        pointer-events: none;
    }
}
