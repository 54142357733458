@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/mixins';
@import '../../styles/includes';

.search-data-cell__text {
    @extend %ace-copy-s;

    font-size: 16px;
    line-height: 22px;
    word-break: break-all;

    @include responsive-desktop-xxl-up() {
        font-size: 18px;
        line-height: 24px;
    }

    &--grey {
        color: map-get($color, ace-grey-medium);
    }

    &--margin-top {
        margin-top: 8px;

        @include responsive-desktop-xxl-up() {
            margin-top: 5px;
        }
    }
}
