@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-message-input__wrapper {
    position: relative;
    width: 100%;
}

.ace-c-message-input {
    @extend %ace-input-field;
    @extend %ace-input-main-font;

    height: auto;
    border-radius: 30px;
    width: 100%;
    padding-right: 125px;
    padding-bottom: 16px;
    padding-top: 17px;
    color: map-get($color, ace-bodycopies);
    overflow: hidden;

    @include responsive-desktop-wide-up() {
        padding-top: 15px;
    }

    &:focus {
        border: solid $border map-get($color, ace-bodycopies);
    }

    &--locked {
        pointer-events: none;
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    &--error {
        border: solid $border map-get($color, ace-red);
    }

    &--disabled {
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }
}

.ace-c-message-input__icon-wrapper {
    @include flex-center();

    position: absolute;
    bottom: 9px;
    right: 5px;
    background-color: map-get($color, ace-red);
    width: 100px;
    height: 50px;
    border-radius: 30px;
    border: solid $border map-get($color, ace-red);
    z-index: 100;
    cursor: pointer;
    resize: none;

    &--disabled {
        cursor: auto;
        background-color: map-get($color, ace-grey-medium);
        border: solid $border map-get($color, ace-grey-medium);
        box-shadow: none;
    }

    &--locked {
        cursor: auto;
        background-color: map-get($color, ace-grey-medium);
        border: solid $border map-get($color, ace-grey-medium);
        box-shadow: none;
    }
}

@-moz-document url-prefix() {
    .ace-c-message-input__icon-wrapper {
        bottom: 5px;
    }
}

.ace-c-message-input__label {
    @extend %ace-input-main-font;

    position: absolute;
    top: 18px;
    left: 27px;
    color: map-get($color, ace-grey-dark);
    transition: all 0.3s;

    @include responsive-desktop-wide-up() {
        top: 16px;
    }

    &--focused {
        @extend %ace-input-placeholder-font;

        top: -28px;
        left: 27px;
        color: map-get($color, ace-grey-medium);

        @include responsive-desktop-wide-up() {
            top: -32px;
        }
    }

    &--locked {
        color: map-get($color, ace-grey-dark);
    }
}

.ace-c-message-input--focused + .ace-c-message-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
}

.ace-c-message-input:-webkit-autofill + .ace-c-message-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
}

.ace-c-message-input:focus + .ace-c-message-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
}

.ace-c-message-input__error-label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-error-label;
}
