/* Mixins */
@import './config';
@import './media';

// Micro clearfix
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
@mixin clearfix() {
    &::after {
        content: '' !important;
        display: block !important;
        clear: both !important;
    }
}

// Applies accessible hiding to elements
@mixin hidden-visually() {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
}

@mixin responsive($sizeFrom: null, $sizeTo: null) {
    @if ($sizeFrom and $sizeTo) {
        @media only screen and (min-width: $sizeFrom) and (max-width: $sizeTo) {
            @content;
        }
    } @else if $sizeTo {
        @media only screen and (max-width: $sizeTo) {
            @content;
        }
    } @else if $sizeFrom {
        @media only screen and (min-width: $sizeFrom) {
            @content;
        }
    }
}

@mixin responsive-phone-only() {
    @media only screen and (max-width: map-get($breakpoints, phone)) {
        @content;
    }
}

@mixin responsive-tablet-portrait-up() {
    @media only screen and (min-width: map-get($breakpoints, phone)) {
        @content;
    }
}

@mixin responsive-tablet-landscape-up() {
    @media only screen and (min-width: map-get($breakpoints, tablet-portrait)) {
        @content;
    }
}

@mixin responsive-desktop-up() {
    @media only screen and (min-width: map-get($breakpoints, tablet-landscape)) {
        @content;
    }
}

@mixin responsive-desktop-wide-up() {
    @media only screen and (min-width: map-get($breakpoints, desktop)) {
        @content;
    }
}

@mixin responsive-desktop-xxl-up() {
    @media only screen and (min-width: map-get($breakpoints, desktop-wide)) {
        @content;
    }
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-space-between() {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-end() {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@mixin flex-start() {
    display: flex;
    align-items: center;
}
