@import '../styles/includes';
@import '../styles/mixins';

.select-app-view__header {
    padding: 60px 30px 0 30px;
}

.select-app-view__app {
    padding: 40px;
    border-radius: 18px;
    background-color: #fafafa;
    box-shadow:
        0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 0, 0, 0.2);

    &--left {
        margin-right: 35px;
    }

    &--right {
        margin-left: 35px;
    }
}

.select-app-view__app-content {
    position: relative;
    padding-top: 130px;
}

.select-app-view__logo-shape {
    @extend %logo-shape;

    justify-content: space-between;
    padding-left: 34px;
    width: calc(100% - 34px);
}

.select-app-view__logo-text {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 55px;

    @include responsive-desktop-up() {
        font-size: 40px;
    }
}
