@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-invoice-submissions-view {
    margin-top: 82px;
    margin-bottom: 84px;
    position: relative;

    @include responsive-desktop-up() {
        margin-top: 102px;
    }

    @include responsive-desktop-xxl-up() {
        margin-top: 110px;
    }
}

.ace-invoice-submissions-view__header {
    position: relative;

    .ace-hoc-button {
        width: 100%;
    }
}
