@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/includes';

.pagination {
    text-align: center;
    margin-top: 50px;
}

.pagination__text {
    @extend %ace-copy-m;

    color: map-get($color, ace-grey-dark);

    &--link {
        cursor: pointer;
    }
}

.pagination__arrow {
    cursor: pointer;
    margin: 0 10px;
    display: inline-block;
    width: 15px;
    height: 15px;

    &--left {
        transform: rotate(90deg);
    }

    &--right {
        transform: rotate(270deg);
    }
}
