@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-c-partner-messages-view {
    position: absolute;
    top: 60px;
    right: -60px;
    width: 540px;
    height: 403px;
    border-radius: 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    padding-bottom: 25px;
    overflow: hidden;
    z-index: 150;
}

.ace-c-partner-messages-view__box {
    padding-left: 95px;
    padding-right: 32px;
    display: flex;
    width: 100%;

    &--header {
        padding-top: 20px;
        height: 50px;
        justify-content: space-between;
    }

    &--message {
        height: 118px;
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;

        &:nth-of-type(odd) {
            background-color: map-get($color, ace-grey-background);
        }
    }
}

.ace-c-partner-messages-view__label,
.ace-c-partner-messages-view__font-small--link {
    line-height: 19px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.25px;
    color: map-get($color, ace-grey-dark);
}

.ace-c-partner-messages-view__label {
    text-align: right;
}

.ace-c-partner-messages-view__mail-link {
    height: 24px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.25px;
    color: map-get($color, ace-red);
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
}

.ace-c-partner-messages-view__font-small--link {
    color: map-get($color, ace-red);

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.ace-c-partner-messages-view__messages-wrapper {
    overflow-y: scroll;
    height: 353px;
}

.ace-c-partner-messages-view__message-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.ace-c-partner-messages-view__message {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.27px;
    color: map-get($color, ace-bodycopies);
    max-height: 63px;
    overflow: hidden;

    &--unread {
        font-weight: bold;
    }
}

.ace-c-partner-messages-view__footer {
    display: flex;
    justify-content: space-between;
}

.ace-c-partner-messages-view__icon-wrapper {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 19px;
    left: 15px;
    display: flex;

    img {
        width: 50px;
        height: auto;
        display: block;
    }
}

.ace-message-box__overlay {
    position: fixed;
    top: 100px;
    margin-left: -100vw;
    margin-right: -100vw;
    width: 200vw;
    height: calc(100vh - 70px);
    background-color: rgba(0, 0, 0, 0.1);
}

.ace-c-partner-messages-view__loading-box {
    @include flex-center();

    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 20px;
}
