$grid-gutter: 30px;

$screen-viewport-xs: 0;
$screen-viewport-sm: 1024px;
$screen-viewport-md: 1200px;
$screen-viewport-lg: 1700px;
$screen-viewport-xl: 2500px;

$grid-size-xs: 100%;
$grid-size-sm: 1000px;
$grid-size-md: 1140px;
$grid-size-lg: 1380px;
$grid-size-xl: 1630px;

$grid-arr: (
    (xs, $screen-viewport-xs, $grid-size-xs),
    (sm, $screen-viewport-sm, $grid-size-sm),
    (md, $screen-viewport-md, $grid-size-md),
    (lg, $screen-viewport-lg, $grid-size-lg),
    (xl, $screen-viewport-xl, $grid-size-xl)
);

.ace-grid__row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $grid-gutter;
}

@each $label, $breakpoint, $width in $grid-arr {
    @media (min-width: #{$breakpoint}) {
        @for $i from 1 through 12 {
            @for $y from 1 through 12 {
                .col-start-#{$label}--#{$y}.col-#{$label}--#{$i} {
                    grid-column: #{$y} / span #{$i};
                }
            }

            .col-#{$label}--#{$i} {
                grid-column-start: span #{$i};
                box-sizing: border-box;
                position: relative;
                min-width: 0;
            }
        }

        .ace-grid {
            position: relative;
            display: block;
        }

        .ace-grid__row {
            width: calc(#{$width} - #{$grid-gutter});
            margin: 0 auto;
        }
    }
}
