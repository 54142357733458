@import '../styles/includes';
@import '../styles/ace-base';
@import '../styles/mixins';

.driver-assignment-wrapper-view {
    padding-top: 40px;
    margin-bottom: 20px;

    @include responsive-desktop-xxl-up() {
        padding-top: 60px;
    }
}

.driver-assignment-wrapper-view__title {
    text-align: center;
    margin-bottom: 33px;
}

.ace-driver-assignment-view__back-link {
    @include flex-start();

    cursor: pointer;
}

.ace-driver-assignment-view__back-link-text {
    @extend %ace-h6;

    font-weight: bold;
    margin-left: 15px;
}

.ace-driver-assignment-view__heading {
    @include flex-center();
}

.ace-driver-assignment-view__heading-text {
    @extend %ace-h3;

    font-weight: 300;
    margin-left: 15px;
}

.driver-assignment-edit__icon {
    @include flex-end();

    cursor: pointer;
}
