@import '../../../styles/includes';

.ace-c-driver-assignment-data-row {
    display: flex;
    width: 100%;
}

.ace-c-driver-assignment-data-row__label {
    display: flex;
    flex: 0 0 40%;

    &__icon {
        margin-right: 15px;
        margin-top: 3px;
    }

    &__text {
        @extend %ace-copy-s;

        line-height: 32px;
    }
}

.ace-c-driver-assignment-data-row__content {
    display: flex;
    flex-direction: column;
    flex: 0 0 60%;
}
