@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/mixins';
@import '../../styles/includes';

.search-row {
    height: 166px;

    &--big {
        height: 240px;
    }
}

.search-row__table-cell {
    display: flex;
    height: 100%;
    align-items: center;

    &--space-between {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 0;
    }

    &:first-child {
        min-width: 160px;

        .ace-c-case-state {
            min-width: 150px;
        }

        @include responsive-desktop-wide-up() {
            min-width: 190px;

            .ace-c-case-state {
                min-width: 180px;
            }
        }
    }
}

.search-row__service-img {
    margin: 0 37px 0 7px;

    @include responsive-desktop-up() {
        margin: 0 42px 0 13px;
    }

    @include responsive-desktop-wide-up() {
        margin: 0 53px 0 22px;
    }

    @include responsive-desktop-xxl-up() {
        margin: 0 92px 0 43px;
    }
}
