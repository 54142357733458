@import '../../styles/config';

.ace-c-icon {
    background-repeat: no-repeat;
    background-position: center;

    &--xxs {
        width: 13px;
        height: 13px;
    }

    &--xs {
        width: 15px;
        height: 15px;
    }

    &--sm {
        width: 21px;
        height: 16px;
    }

    &--smd {
        width: 25px;
        height: 16px;
    }

    &--md {
        width: 25px;
        height: 25px;
    }

    &--lg {
        width: 40px;
        height: 40px;
    }

    &--xl {
        width: 50px;
        height: 50px;
    }

    &--xxl {
        width: 60px;
        height: 60px;
    }
}

$multicolor-icons-file-names: (
    abschleppen,
    bergung,
    car-strike,
    calendar,
    checkmark,
    close,
    download,
    expand-arrow,
    eye,
    fahrzeugoffnung,
    location,
    pannenhilfe,
    pannenhilfe-strike,
    pen,
    pickup,
    pickup-strike,
    plus,
    starthilfe,
    user-strike,
    leerfahrt-strike,
    new-status,
    in-service-status,
    assign-status,
    car-front,
    time-status,
    check-circle,
    home,
    unavailable,
    busy-towing,
    busy-approaching,
    busy-recovery,
    history,
    close-status,
    mehrere,
    kontakt,
    support,
    reason-traffic,
    reason-other,
    reason-location-too-far,
    reason-no-capacity,
    reason-assistance-refused,
    reason-canceled,
    reason-no-customer,
    reason-no-damage-detected,
    reason-incorrect-location,
    person-on-site,
    member,
);

@each $multi-icon in $multicolor-icons-file-names {
    .ace-c-icon__#{$multi-icon} {
        background-image: url('../assets/icons/#{$multi-icon}.svg');

        &--red {
            background-image: url('../assets/icons/#{$multi-icon}.svg?stroke=#{map-get($color, ace-red)}');
        }

        &--fill-grey {
            background-image: url('../assets/icons/#{$multi-icon}.svg?fill=#9F9FA0');
        }

        &--fill-white {
            background-image: url('../assets/icons/#{$multi-icon}.svg?fill=#{map-get($color, ace-white)}');
        }

        &--fill-red {
            background-image: url('../assets/icons/#{$multi-icon}.svg?fill=#{map-get($color, ace-red)}');
        }

        &--white {
            background-image: url('../assets/icons/#{$multi-icon}.svg?stroke=#{map-get($color, ace-white)}');
        }

        &--grey {
            background-image: url('../assets/icons/#{$multi-icon}.svg?stroke=#9F9FA0');
        }
    }
}

$monocolor-icons-file-names: (
    arrow-right,
    attachment,
    big-pin,
    car,
    clock,
    cloud,
    danger,
    floating,
    hourly,
    info,
    info-red,
    letter,
    magnifying-glass,
    map-extended,
    minus,
    play,
    pointer,
    user,
    leerfahrt,
    circle-plus,
    directory-closed,
    directory-opened,
    directory-list-closed,
    directory-list-opened,
    directory-section-closed,
    directory-section-opened,
    directory-euro-closed,
    directory-euro-opened,
    file-dat,
    file-pdf,
    file-zip,
    file-html,
    help,
    assignment,
    book,
    arrow-forward,
    driver-accepted-status,
    approaching-status,
    service-finished-status,
    driver-assigned-status,
    arrow-back,
    person,
    pin,
    comment,
    warning,
    towing-status,
);

@each $mono-icon in $monocolor-icons-file-names {
    .ace-c-icon__#{$mono-icon} {
        background-image: url('../assets/icons/#{$mono-icon}.svg');
    }
}
