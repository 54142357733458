@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';

.ace-additional-services__service-wrapper {
    position: relative;
}

.ace-additional-services__input-wrapper {
    position: relative;
}

.ace-additional-services__euro-sign-wrapper {
    position: absolute;
    top: 13px;
    right: 2px;
    padding: 4px 10px 4px 4px;
    background: white;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.ace-additional-services__euro-sign {
    @extend %ace-copy-m;

    color: map-get($color, ace-grey-dark);
}

.ace-additional-services__list-item {
    margin-top: 62px;
}

.ace-additional-services__cancel-field {
    display: flex;
    justify-content: flex-start;
    min-height: 60px;

    .ace-link-m {
        align-self: center;
    }
}

.ace-additional-services__bottom-margin {
    margin-bottom: 42px;

    &--disable-element {
        opacity: 0.6;
        pointer-events: none;
        user-select: none;
    }
}

.additional-service-wrapper {
    &--error {
        @extend %error-container;
    }
}

.ace-additional-services__comment {
    position: absolute;
    top: 0;
    left: 0;
}
