@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-driver-assignments-view {
    margin-top: 82px;
    margin-bottom: 84px;
    position: relative;

    @include responsive-desktop-up() {
        margin-top: 102px;
    }

    @include responsive-desktop-xxl-up() {
        margin-top: 110px;
    }
}

.ace-driver-assignments-view__notification-container {
    @include flex-center();

    height: 112px;
}

.ace-driver-assignments-view__header {
    position: relative;

    .ace-hoc-button {
        width: 100%;
    }
}

.ace-driver-assignments-view__archive-button-wrapper {
    width: 190px;
    margin: 0 auto;
    position: relative;
}

.ace-driver-assignments-view__scroll-up-wrapper {
    position: fixed;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
}

.ace-driver-assignments-view__scroll-up-container {
    position: relative;
}

.ace-driver-assignments-view__scroll-up-button {
    @include flex-center();

    position: absolute;
    background-color: map-get($color, ace-white);
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
}

.ace-c-driver-assignments-panel__table-header {
    padding-bottom: 13px;
    margin-bottom: 5px;
    position: sticky;
    z-index: 2;
    background: map-get($color, ace-white);
    top: 179px;

    @include responsive-desktop-up() {
        top: 199px;
    }

    @include responsive-desktop-wide-up() {
        top: 199px;
    }

    @include responsive-desktop-xxl-up() {
        padding-top: 18px;
        padding-bottom: 29px;
        top: 278px;
    }
}
