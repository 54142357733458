@import '../../../styles/config';
@import '../../../styles/mixins';

.ace-c-driver-assignment-row__icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    background: #fafafa;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

    &--red {
        background: map-get($color, ace-red);
    }

    &--grey {
        background: #9f9fa0;
    }
}

.ace-c-driver-assignment-row__last-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ace-c-driver-assignment-row__arrow-icon {
    margin-left: 5px;
}
