@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/includes';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap');

.ace-header__wrapper {
    position: relative;
    background: white;
}

.ace-upper-header {
    width: 100%;
    height: 30px;
    background: map-get($color, ace-headlines);
    position: absolute;
    z-index: 5;
}

.ace-upper-header__navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
}

.ace-upper-header__navigation-link {
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ace-upper-header__navigation-title {
    font-size: 16px;
    line-height: 19px;
    color: white;
    font-family: 'Fira Sans', sans-serif;
}

.ace-upper-header__ .ace-lower-header__wrapper {
    background-color: map-get($color, ace-white);
}

.ace-lower-header {
    display: flex;
    height: map-get($app-layout, header-height);
    background-color: map-get($color, ace-white);
    position: relative;
    user-select: none;
}

.ace-lower-header__logo-shape {
    @extend %logo-shape;

    width: 255px;
    justify-content: flex-end;

    &--has-title {
        justify-content: space-between;
    }

    &:hover {
        cursor: pointer;
    }
}

.ace-lower-header__logo-ribbon {
    height: 100%;
    position: absolute;
    margin-left: -50vw;
    width: 50vw;
    left: 0;
    background-color: map-get($color, ace-red);
}

.ace-lower-header__logo-text {
    color: map-get($color, ace-white);
    font-size: 40px;
    line-height: 62px;
    font-weight: bold;
    max-width: 132px;
}

.ace-lower-header__logo {
    width: 106px;
    height: 43px;
}

.ace-lower-header__navigation-top {
    display: flex;
    color: map-get($color, ace-bodycopies);
    justify-content: center;
    width: 100%;
    text-align: center;
}

.ace-lower-header__navigation-link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    padding-top: 30px;

    @include responsive-desktop-up() {
        margin-right: 40px;
    }

    @include responsive-desktop-wide-up() {
        margin-right: 70px;
    }

    @include responsive-desktop-xxl-up() {
        margin-right: 90px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.ace-lower-header__navigation-title {
    @extend %ace-copy-m;

    cursor: pointer;
    text-transform: uppercase;

    .ace-lower-header__navigation-link--active & {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            display: block;
            height: 5px;
            border-radius: 2.5px;
            width: 100%;
            background-color: map-get($color, ace-red);
        }
    }
}

.ace-lower-header__user-options-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 30px;
}

.ace-lower-header__user-option-wrapper {
    display: inline-flex;
    justify-content: flex-end;
    background-color: map-get($color, ace-white);
    align-items: center;
    position: relative;
    line-height: 21px;
    font-size: 16px;
    font-weight: 300;
}

.ace-lower-header__user-icon-wrapper {
    position: relative;
}

.ace-lower-header__letter-icon-wrapper {
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.ace-lower-header__letter-icon-bubble {
    @include flex-center();

    position: absolute;
    top: 0;
    right: 0;
    min-width: 17px;
    height: 17px;
    padding: 0 4px;
    border-radius: 15px;
    background-color: map-get($color, ace-red);
}

.ace-lower-header__message-count {
    line-height: 18px;
    font-size: 13px;
    font-weight: 600;
    color: map-get($color, ace-white);
    margin-bottom: 1px;
}

.ace-lower-header__options-dropdown {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    min-width: 255px;
    border-radius: 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    padding-bottom: 25px;
}

.ace-lower-header__greeting {
    @extend %ace-copy-m;

    font-weight: bold;
    padding: 20px 30px;
}

.ace-lower-header__options-link {
    @extend %ace-copy-m;

    cursor: pointer;
    display: block;
    padding: 10px 30px;

    &:nth-child(even) {
        background-color: map-get($color, ace-grey-superlight);
    }
}

.ace-lower-header__bottom-shadow {
    position: absolute;
    top: 70px;
    height: 30px;
    width: 100%;
    z-index: -1;

    &--before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: transparent;
        border-radius: 50% / 30px;
        box-shadow: map-get($color, ace-grey-light) 0 5px 10px;
        clip: rect(30px, auto, 50px, 0);
    }
}
