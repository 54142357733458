.ace__info-window-title {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-style: italic;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
}

.ace__info-window-text {
    color: #434344;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
}
