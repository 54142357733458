@import '../../styles/config';
@import '../../styles/ace-base';

.member-form__wrapper {
    margin-bottom: 70px;

    .ace-c-select__options-wrapper {
        height: 350px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-thumb {
            background: map-get($color, ace-red);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            width: 30px;
            background-color: transparent;
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: map-get($color, ace-red-dark);
        }
    }
}

.member-form__row {
    margin-top: 62px;

    &--first {
        margin-top: 54px;
    }
}

.member-form__checkboxes {
    margin-top: 50px;
}

.member-form__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 13px;
}

.member-form__checkbox-label {
    margin-left: 42px;
}
