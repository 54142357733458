@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/ace-base';
@import '../../styles/includes';

.ace-tab-links__wrapper {
    position: sticky;
    top: 70px;
    background-color: map-get($color, ace-white);
    padding-top: 45px;
    z-index: 2;

    @include responsive-desktop-up() {
        padding-top: 83px;
        top: 50px;
    }

    @include responsive-desktop-wide-up() {
        padding-top: 80px;
        top: 53px;
    }

    @include responsive-desktop-xxl-up() {
        padding-top: 80px;
        top: 50px;
    }
}

.ace-tab-links__wraper--modal-open {
    z-index: 150;
}

.ace-tab-links {
    background: map-get($color, ace-white);
}

.ace-tab-links__bottom-shadow {
    position: relative;
    top: -25px;
    height: 30px;

    &--before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: transparent;
        border-radius: 50% / 30px;
        box-shadow: map-get($color, ace-grey-light) 0 5px 10px;
        clip: rect(30px, auto, 50px, 0);
    }
}

.ace-tab-link__title {
    @extend %ace-h6;

    color: map-get($color, ace-headlines);
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 90px;
    display: inline-flex;
    align-items: center;
    letter-spacing: -0.25px;
    padding-bottom: 10px;
    font-weight: 300;
    z-index: 1;

    @include responsive-desktop-up() {
        padding-bottom: 9px;
    }

    @include responsive-desktop-xxl-up() {
        padding-bottom: 6px;
        line-height: 30px;
        font-size: 22px;
    }

    &--active {
        font-weight: bold;

        &::after {
            position: absolute;
            width: 100%;
            content: '';
            left: 0;
            bottom: -5px;
            height: 5px;
            border-radius: 2.5px;
            background-color: map-get($color, ace-red);
        }
    }
}

.ace-tab-link__result-counter {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: map-get($color, ace-red);
    color: map-get($color, ace-white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OpenSans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-left: 6px;
}
