@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-c-table-body {
    > * {
        position: relative;
        overflow: hidden;
    }

    &--has-rows {
        > * {
            &:nth-child(odd) {
                background-color: map-get($color, ace-grey-row);
            }

            &:hover {
                box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
                z-index: 1;
            }
        }
    }
}
