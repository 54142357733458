@import '../styles/includes';

.ace-driver-assignment-active-view__detail-row {
    display: flex;
}

.ace-driver-assignment-active-view__detail-row-title {
    display: flex;
    flex: 0 0 40%;
}

.ace-driver-assignment-active-view__detail-row-content {
    flex: 1;
}

.ace-driver-assignment-active-view__detail-row-icon {
    margin-right: 15px;
    margin-top: 3px;
}

.ace-driver-assignment-active-view_detail-row-text {
    @extend %ace-copy-s;

    line-height: 32px;
}

.ace-driver-assignment-map__container {
    height: 400px;
    position: relative;

    &--full-screen {
        height: 50vh;
        z-index: 10000;
    }
}

.ace-driver-assignment-map__overlay {
    @extend %overlay;

    z-index: 999;
}

.ace-driver-assignment-active-view__drivers-list {
    overflow-y: auto;
    height: 360px;
}

.ace-c-assigned-driver__row {
    @include flex-space-between();

    height: 72px;
    padding: 0 45px 0 15px;
    cursor: pointer;

    &--active {
        background: rgba(0, 0, 0, 0.08);
    }
}

.ace-c-assigned-driver__icon {
    @include flex-center();

    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
    border: 1px solid #9f9fa0;

    &--active {
        border: none;
        background: map-get($color, ace-red);
    }
}

.ace-c-assigned-driver__name {
    @extend %ace-copy-s;

    flex: 0 0 45%;
}

.ace-c-assigned-driver__status {
    @extend %ace-copy-s;

    flex: 0 0 35%;
}

.ace-c-pop-up {
    .ace-c-service-option {
        width: 49%;
    }
}
