@import '../../../styles/config';
@import '../../../styles/includes';

.ace-c-open-invoice-submission-row__plus-icon {
    margin-left: 6px;
}

.ace-c-open-invoice-submission-cell__small {
    @extend %ace-copy-s;

    margin-left: -15px;
    font-size: 13px;
}
