@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-driver-row {
    @include flex-start();

    height: 72px;
    cursor: pointer;

    &--active {
        background: rgba(0, 0, 0, 0.08);
    }

    &--disabled {
        cursor: default;
    }
}

.ace-c-driver-row__initials {
    @include flex-center();

    width: 40px;
    margin: 0 15px;
    height: 40px;
    background: #9f9fa0;
    color: #434344;
    font-weight: bold;
    font-size: 20px;
    border-radius: 50%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    &--red::after {
        background: map-get($color, ace-red);
    }

    &--green::after {
        background: green;
    }

    &--grey::after {
        background: #9f9fa0;
    }
}

.ace-c-driver-row__driver {
    @include flex-space-between();

    flex: 1;
    height: 72px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-right: 25px;
}

.ace-c-driver-row__driver-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

.ace-c-driver-row__driver-name {
    @extend %ace-copy-s;
}

.ace-c-driver-row__driver-status {
    color: #9f9fa0;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
}
