@use 'sass:math';

@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/media';

$max-grid-width: (
    phone: 612px,
    tablet-portrait: 970px,
    tablet-landscape: 1110px,
    desktop: 1350px,
    desktop-wide: 1600px,
);

.ace-c-pop-up__scroll-container {
    height: 80vh;
    margin: 0 auto;
    background: map-get($color, ace-white);
    overflow-y: auto;
    padding: 50px;

    @include responsive-tablet-portrait-up() {
        max-width: map-get($max-grid-width, phone) - math.div(map-get($max-grid-width, phone), 6);
        margin-left: auto;
        margin-right: auto;
    }

    @include responsive-tablet-landscape-up() {
        max-width: map-get($max-grid-width, tablet-portrait) - math.div(map-get($max-grid-width, tablet-portrait), 6);
        padding-left: 27px;
        padding-right: 27px;
    }

    @include responsive-desktop-up() {
        max-width: map-get($max-grid-width, tablet-landscape) - math.div(map-get($max-grid-width, tablet-landscape), 6);
        padding-left: 45px;
        padding-right: 45px;
    }

    @include responsive-desktop-wide-up() {
        max-width: map-get($max-grid-width, desktop) - math.div(map-get($max-grid-width, desktop), 6);
        padding-left: 175px;
        padding-right: 175px;
    }

    @include responsive-desktop-xxl-up() {
        max-width: map-get($max-grid-width, desktop-wide) - math.div(map-get($max-grid-width, desktop-wide), 6);
        padding-left: 225px;
        padding-right: 225px;
    }
}

.ace-c-pop-up__images {
    width: 100%;
}

.ace-c-pop-up__list {
    padding-left: 30px;
}

.ace-c-pop-up__button-wrapper {
    display: flex;
    justify-content: center;
}

.ace-c-pop-up__button-container {
    width: 33%;
}
