@import '../styles/config';
@import '../styles/functions';
@import '../styles/mixins';
@import '../styles/includes';

.search-results-view__table-body {
    padding-top: 50px;

    @include responsive-desktop-xxl-up() {
        padding-top: 100px;
    }

    > * {
        cursor: pointer;

        &:hover {
            box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
            z-index: 2;
        }

        &:nth-child(odd) {
            background-color: map-get($color, ace-grey-row);
        }
    }
}

.search-results-view__table-row {
    height: 166px;

    &--dispatch {
        height: 240px;
    }
}

.search-results-view__table-cell {
    display: flex;
    height: 100%;
    align-items: center;

    &--space-between {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 0;
    }

    &:first-child {
        min-width: 160px;

        .ace-c-case-state {
            min-width: 150px;
        }

        @include responsive-desktop-wide-up() {
            min-width: 190px;

            .ace-c-case-state {
                min-width: 180px;
            }
        }
    }
}

.search-results-view__cell-label-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.search-results-view__service-img {
    margin: 0 37px 0 7px;

    @include responsive-desktop-up() {
        margin: 0 42px 0 13px;
    }

    @include responsive-desktop-wide-up() {
        margin: 0 53px 0 22px;
    }

    @include responsive-desktop-xxl-up() {
        margin: 0 92px 0 43px;
    }
}

.search-results-view__value {
    @extend %ace-copy-s;

    font-size: 16px;
    line-height: 22px;
    word-break: break-all;

    @include responsive-desktop-xxl-up() {
        font-size: 18px;
        line-height: 24px;
    }

    &--grey {
        color: map-get($color, ace-grey-medium);
    }

    &--margin-top {
        margin-top: 8px;

        @include responsive-desktop-xxl-up() {
            margin-top: 5px;
        }
    }
}
