@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-input__wrapper {
    position: relative;
}

.ace-c-input {
    @extend %ace-input-field;
    @extend %ace-input-main-font;

    width: 100%;
    padding-right: 25px;
    color: map-get($color, ace-bodycopies);

    &:focus {
        border: solid $border map-get($color, ace-bodycopies);
        z-index: 1;
        position: relative;
    }

    &--autocomplete:focus {
        border: solid $border map-get($color, ace-grey-medium);
    }

    &--locked {
        pointer-events: none;
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    &--error {
        border: solid $border map-get($color, ace-red);
    }

    &--disabled {
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    &--squared {
        @extend %ace-input-main-font-md;

        padding-left: 10px;
        border-radius: 5px;
        box-shadow: none;
    }

    &--sm {
        height: 40px;
    }
}

.ace-c-input__label {
    @extend %ace-input-main-font;

    position: absolute;
    top: 18px;
    left: 27px;
    color: map-get($color, ace-grey-dark);
    transition: all 0.3s;
    white-space: nowrap;

    @include responsive-desktop-wide-up() {
        top: 16px;
    }

    &--focused {
        @extend %ace-input-placeholder-font;

        top: -28px;
        left: 27px;
        color: map-get($color, ace-grey-medium);

        @include responsive-desktop-wide-up() {
            top: -32px;
        }
    }

    &--locked {
        color: map-get($color, ace-grey-dark);
    }

    &--integrated {
        top: -15px;
        left: 10px;
        color: map-get($color, ace-bodycopies);
        background-color: map-get($color, ace-white);
        font-size: 14px;
    }
}

.ace-c-input:-webkit-autofill + .ace-c-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
}

.ace-c-input:focus + .ace-c-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);

    &--integrated {
        top: -15px;
        left: 10px;
        color: map-get($color, ace-bodycopies);
        background-color: map-get($color, ace-white);
        font-size: 14px;
        z-index: 2;
    }
}

.ace-c-input__error {
    @extend %ace-input-error-label;

    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: -0.47px;

    @include responsive-desktop-wide-up() {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
    }

    &--sm {
        padding-left: 10px;
        font-size: 12px;
        line-height: 18px;
    }
}

.pac-container {
    position: relative;
    transform: translateY(-30px);
    padding: 40px 10px 20px 10px;
    width: 100%;
    border-radius: 0 0 30px 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    z-index: 0;
}

.pac-item {
    @extend %ace-input-main-font;

    height: 42px;
    padding-left: 27px;
    padding-right: 27px;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    color: map-get($color, ace-bodycopies);

    &:nth-child(odd) {
        background-color: map-get($color, ace-grey-superlight);
    }

    &:hover {
        cursor: pointer;

        &::after {
            content: '';
            width: 5px;
            border-radius: 2.5px;
            background-color: map-get($color, ace-red);
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    @include responsive-desktop-up() {
        height: 48px;
    }

    @include responsive-desktop-wide-up() {
        height: 54px;
    }

    @include responsive-desktop-xxl-up() {
        height: 56px;
    }
}

.pac-logo::after {
    background-image: none;
}
