@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-c-filter-dropdown__container {
    cursor: pointer;
}

.ace-c-filter-dropdown__label {
    @extend %ace-input-main-font;

    color: map_get($color, ace-grey-dark);
    background-color: map_get($color, ace-white);
    user-select: none;
    font-size: 16px;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
}

.ace-c-table-header-cell__text {
    &.select-active {
        text-decoration: underline;
    }
}

.ace-c-filter-dropdown__label-icon {
    margin-left: 5px;
    cursor: pointer;

    &.expanded {
        transform: rotate(180deg);
    }
}

.ace-c-filter-dropdown__wrapper {
    padding-bottom: 20px;
    background-color: map_get($color, ace-white);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.22);
    width: 255px;
    position: absolute;
    top: 35px;
    left: -20px;
    z-index: 10;

    &.is-last-cell {
        left: auto;
        right: -20px;
    }

    @include responsive-desktop-xxl-up() {
        top: 55px;
    }
}
