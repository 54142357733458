@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-autocomplete {
    position: relative;

    &__input {
        position: relative;
    }

    &__input:focus {
        z-index: 2;
    }

    &__dropdown {
        position: absolute;
        top: 30px;
        left: 0;
        padding: 40px 0 20px 0;
        z-index: 1;
        width: 100%;
        border-radius: 0 0 30px 30px;
        box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
        background-color: map-get($color, ace-white);
    }
}
