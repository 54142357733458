@import '../styles/config';

.ace-driver-status-view {
    padding-top: 50px;

    .ace-c-select__options-wrapper {
        max-height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-thumb {
            background: map-get($color, ace-red);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            width: 30px;
            background-color: transparent;
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: map-get($color, ace-red-dark);
        }
    }
}
