@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-c-case-messages-view {
    margin-bottom: 50px;
}

.ace-c-case-messages-view__header {
    @include flex-space-between();

    margin-bottom: 30px;
    cursor: pointer;
}

.ace-c-case-messages-view__toggle-icon {
    cursor: pointer;
    margin-right: 0;
    font-size: 0;
    display: flex;
    align-items: center;

    .overview-data__icon-text {
        display: none;
    }

    @include responsive-desktop-up() {
        margin-right: 13px;
    }

    &--up {
        transform: rotate(180deg);
    }
}

.ace-c-case-messages-view__message-wrapper {
    display: flex;
    flex-direction: column;
}

.ace-c-case-messages-view__message {
    padding: 30px;
    box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.22);
    margin-bottom: 40px;
    position: relative;

    @include responsive-desktop-up() {
        width: 600px;
    }

    @include responsive-desktop-wide-up() {
        width: 700px;
    }

    &--left {
        background-color: map-get($color, ace-white);

        @include responsive-desktop-up() {
            right: 30px;
        }
    }

    &--right {
        background-color: map-get($color, ace-grey-background);

        @include responsive-desktop-up() {
            align-self: flex-end;
            left: 30px;
        }
    }

    &:last-of-type {
        margin-bottom: 60px;
    }
}

.ace-c-case-messages-view__message-header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.ace-c-case-messages-view__message-header-label {
    line-height: 19px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.25px;
    color: map-get($color, ace-grey-dark);
}

.ace-c-case-messages-view__message-text {
    @extend %ace-copy-s;

    line-height: 1.75;
    word-break: break-word;
    white-space: pre-wrap;
}

.ace-c-case-messages-view__message-icon-wrapper {
    display: flex;
    align-items: center;
}

.ace-c-case-messages-view__message-icon {
    display: inline-block;
    margin-right: 12px;
    width: 50px;
    height: 20px;
}

.ace-c-case-messages-view__no-message-wrapper {
    margin-top: 40px;
    text-align: center;
}
