@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/includes';

.ace-c-dropdown-trigger {
    cursor: pointer;
}

.ace-c-dropdown {
    display: none;
    position: absolute;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    padding-bottom: 25px;
    cursor: default;

    &--visible {
        display: block;
        z-index: 2;
    }

    &--top-offset-small {
        margin-top: 20px;
    }

    &--top-offset-medium {
        margin-top: 40px;
    }

    &--top-offset-large {
        margin-top: 60px;
    }

    &--bottom-right {
        top: 60px;
        right: 0;
        min-width: 255px;
        border-radius: 30px;
    }

    &--bottom-center {
        top: 35px;
        left: -20px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.22);
        width: 255px;
        z-index: 10;

        &.is-last-cell {
            left: auto;
            right: -20px;
        }

        @include responsive-desktop-xxl-up() {
            top: 55px;
        }
    }

    &--select-dropdown {
        top: 30px;
        left: 0;
        padding: 40px 0 20px 0;
        width: 100%;
        border-radius: 0 0 30px 30px;
        box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    }

    &--scrollable {
        overflow-y: scroll;
        max-height: 300px;
        border-radius: 0 0 0 30px;
    }

    &__option {
        cursor: pointer;
    }
}
