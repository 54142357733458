@import '../styles/includes';

.ace-driver-assignment-inactive-view__download-section {
    margin: -100px 0 60px 0;
}

.ace-driver-assignment-inactive-view__section {
    padding: 30px 0 40px 0;

    &:nth-child(odd) {
        background: rgba(#f6f7f6, 0.7);
    }
}

.ace-driver-assignment-inactive-view__text {
    @extend %ace-copy-s;

    line-height: 28px;
}

.ace-driver-assignment-inactive-view__attachments {
    display: flex;
    flex-wrap: wrap;
}

.ace-driver-assignment-inactive-view__attachment-wrapper {
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-top: 19px;
    flex: 0 0 29%;

    &:nth-child(3n) {
        margin-right: 0;
    }
}

.ace-driver-assignment-inactive-view__attachment-icon-wrapper {
    display: block;
    margin-right: 10px;
    overflow: hidden;
    flex: 0 0 50px;
}

.ace-driver-assignment-inactive-view__attachment-icon-text {
    @extend %ace-copy-s;

    word-break: break-all;
    flex: 1;
}

.ace-driver-assignment-inactive-view__attachment-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
}

.ace-driver-assignment-inactive-view__attachments-title {
    margin-top: 30px;
}
