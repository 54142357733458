@import '../../../styles/includes';
@import '../../../styles/grid-variables';

.ace-c-summary-detail-row {
    display: flex;

    &__label {
        flex: 0 0 135px;
        margin-right: map-get($gutter-size, tablet-landscape);

        @include responsive-desktop-up() {
            flex: 0 0 160px;
            margin-right: map-get($gutter-size, desktop);
        }

        @include responsive-desktop-wide-up() {
            flex: 0 0 200px;
            margin-right: map-get($gutter-size, desktop-wide);
        }

        @include responsive-desktop-xxl-up() {
            flex: 0 0 300px;
            margin-right: map-get($gutter-size, desktop-xxl);
        }

        &__text {
            @extend %ace-copy-s;

            word-break: break-word;
            line-height: 28px;

            @include responsive-desktop-up() {
                line-height: 30px;
            }

            @include responsive-desktop-wide-up() {
                line-height: 30px;
            }

            @include responsive-desktop-xxl-up() {
                line-height: 30px;
            }
        }
    }
}
