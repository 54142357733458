@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/mixins';

.ace-c-service-option {
    display: inline-flex;
    width: 50%;
    margin-bottom: 20px;

    &:nth-last-child(2) {
        margin-bottom: 0;
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
}

.ace-c-service-option__icon {
    @include flex-center();

    width: 60px;
    height: 60px;
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    background-color: map-get($color, ace-white);
    opacity: 0.5;
    border: solid $border map-get($color, ace-red);
    cursor: pointer;

    &--selected {
        opacity: 1;
        background-color: map-get($color, ace-red);
    }

    &--disabled {
        pointer-events: none;
        border: solid $border map-get($color, ace-grey-medium);
        box-shadow: none;
    }

    &--selected-disabled {
        background-color: map-get($color, ace-grey-medium);
    }

    &--errors {
        border: solid $border map-get($color, ace-red);
    }
}

.ace-c-service-option__icon-strike {
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    opacity: 0.5;
    cursor: pointer;
    position: relative;

    &--selected {
        opacity: 1;
        background-color: map-get($color, ace-red);
    }

    &--disabled {
        pointer-events: none;
        box-shadow: none;
    }

    &--selected-disabled {
        background-color: map-get($color, ace-grey-medium);
    }
}

.ace-c-service-option__text-wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-left: 20px;
    position: relative;
}
