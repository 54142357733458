@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-select {
    position: relative;
    display: block;
    width: 100%;
}

.ace-c-select__input-wrapper {
    position: relative;
}

.ace-c-select__field {
    @extend %ace-input-field;

    padding-right: 54px;
    position: relative;
    display: flex;
    align-items: center;
    color: map-get($color, ace-grey-dark);
    cursor: pointer;
    letter-spacing: -0.25px;
    word-break: break-word;

    &--disabled {
        pointer-events: none;
        box-shadow: none;
        border: solid $border map-get($color, ace-grey-light);
        cursor: not-allowed;
        color: map-get($color, ace-bodycopies);
    }

    &--locked {
        pointer-events: none;
        box-shadow: none;
        border: solid $border map-get($color, ace-grey-light);
        cursor: not-allowed;
        color: map-get($color, ace-grey-dark);
    }

    &--error {
        border: solid $border map-get($color, ace-red);
        z-index: 3;
    }

    &--focused {
        background-color: map-get($color, ace-grey-background);
        box-shadow: none;
        transition: all 0.3s ease;
        z-index: 3;
    }
}

.ace-c-select__placeholder {
    @extend %ace-input-main-font;

    color: map-get($color, ace-grey-dark);

    &--disabled {
        color: map-get($color, ace-bodycopies);
    }

    &--locked {
        color: map-get($color, ace-grey-dark);
    }

    &--focused {
        color: map-get($color, ace-grey-dark);
    }

    &--error {
        color: map-get($color, ace-bodycopies);
    }

    &--selected {
        color: map-get($color, ace-bodycopies);
    }
}

.ace-c-select__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    position: absolute;
    color: map-get($color, ace-grey-medium);
}

.ace-c-select__icon {
    position: absolute;
    display: flex;
    align-items: center;
    right: 14px;
    transition: transform 0.3s ease;

    &--focused {
        transform: rotate(180deg);
    }
}

.ace-c-select__options-wrapper {
    position: absolute;
    top: 30px;
    left: 0;
    padding: 40px 0 20px 0;
    display: block;
    z-index: 2;
    width: 100%;
    border-radius: 0 0 30px 30px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
}

.ace-c-select__option {
    @extend %ace-input-main-font;

    height: 42px;
    padding-left: 27px;
    padding-right: 27px;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    color: map-get($color, ace-bodycopies);
    cursor: pointer;

    &:nth-child(odd) {
        background-color: map-get($color, ace-grey-superlight);
    }

    &:hover {
        &::after {
            content: '';
            width: 5px;
            border-radius: 2.5px;
            background-color: map-get($color, ace-red);
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    @include responsive-desktop-up() {
        height: 48px;
    }

    @include responsive-desktop-wide-up() {
        height: 54px;
    }

    @include responsive-desktop-xxl-up() {
        height: 56px;
    }
}

.ace-c-select__error-label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-error-label;
}
