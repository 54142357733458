@import '../styles/includes';
@import '../styles/mixins';

.ace-support-view {
    font-family: 'Open Sans', sans-serif;
    padding: 40px 0;
}

.ace-support__text-area-wrapper {
    padding-top: 50px;

    @include responsive-desktop-wide-up() {
        height: 100%;
        padding-top: 32px;
    }
}

.ace-support__text-area-container {
    height: 135px;

    @include responsive-desktop-wide-up() {
        height: 100%;
    }

    .ace-c-text-area {
        height: 100%;
    }
}

.ace-c-support-view__validation {
    display: flex;
}

.ace-c-support-view__validation__text {
    @extend %ace-copy-m;

    &--error {
        margin-left: 10px;
        color: map-get($color, ace-red);
    }
}

.ace-support-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;

    &__content {
        background-color: #fff;
        width: 920px;
        height: 344px;
        opacity: 1;
        padding: 50px;

        &__header {
            margin: 0 45px 45px 45px;
        }
    }
}

.ace-support-popup__content__buttons-container {
    display: flex;
    margin: 0 70px 45px;
    flex-grow: 1;

    div {
        width: 350px;
        text-align: center;
    }

    div:not(:last-child) {
        margin-right: 25px;
    }
}
