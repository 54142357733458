@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/mixins';
@import '../../styles/includes';
@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
    font-family: inherit;
}

.ace-c-date-picker__wrapper {
    position: relative;
}

.ace-c-date-picker__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
    position: absolute;
}

.ace-c-date-picker__close-icon {
    @include flex-center();

    position: absolute;
    right: 10px;
    top: 9px;
    cursor: pointer;

    &--disabled {
        pointer-events: none;
        cursor: none;
    }
}

.ace-c-date-picker__calendar-icon {
    position: absolute;
    pointer-events: none;

    &--input-style {
        right: 13px;
        top: 9px;
        z-index: 3;
    }

    &--button-style {
        left: 13px;
        top: 1px;
        font-size: 0;
    }
}

.ace-c-date-picker__field {
    @extend %ace-input-main-font;

    box-sizing: border-box;
    position: relative;
    color: map-get($color, ace-bodycopies);
    letter-spacing: -0.25px;
    width: 100%;

    &::placeholder {
        @extend %ace-input-main-font;

        line-height: 42px;
    }

    &--input-style {
        height: 60px;
        border-radius: 99px;
        box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
        border: solid $border map-get($color, ace-grey-medium);
        background-color: map-get($color, ace-white);
        padding: 0 50px 0 25px;
        font-weight: 300;
        z-index: 3;

        &:focus {
            border: solid $border map-get($color, ace-bodycopies);
        }
    }

    &--input-style-disabled {
        height: 60px;
        border-radius: 99px;
        box-shadow: none;
        border: solid $border map-get($color, ace-grey-light);
        background-color: map-get($color, ace-white);
        padding: 0 50px 0 25px;
        font-weight: 300;
        z-index: 3;
        pointer-events: none;
    }

    &--button-style {
        height: 42px;
        padding: 0 13px 0 108px;
        font-weight: bold;
        letter-spacing: -0.25px;
        background-color: transparent;
        border: 0;
        text-align: left;
        cursor: pointer;
    }
}

.ace-c-date-picker__button-label {
    @extend %ace-input-main-font;

    position: absolute;
    pointer-events: none;
    cursor: pointer;
    top: 9px;
    left: 65px;
    font-weight: 300;
}

.react-datepicker__navigation {
    width: 24px;
    height: 24px;
    border: 0;

    &--next {
        @extend %arrow-icon;

        right: 13px;
    }

    &--previous {
        @extend %arrow-icon;

        transform: rotate(180deg);
        left: 13px;
    }
}

.react-datepicker__month {
    &--selected {
        font-weight: bold;
        background-color: transparent;
        color: map_get($color, ace-bodycopies);
    }
}

.react-datepicker__current-month {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: map-get($color, ace-red);
    padding: 0 0 0 0;
}

.ace-c-date-picker__calendar {
    background-color: transparent;
    border: none;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: map-get($color, ace-bodycopies);
    width: 100%;
    display: flex;

    &--input-style {
        .react-datepicker__current-month {
            padding: 39px 0 0 0;
        }

        .react-datepicker__navigation {
            top: 39px;
        }

        .react-datepicker__month {
            margin: 8px 0 20px 0;
        }

        .react-datepicker__header {
            background-color: map-get($color, ace-white);
            padding-top: 0;
            border: 0;
        }
    }

    &--button-style {
        .react-datepicker__current-month {
            padding: 0 0 0 0;
        }

        .react-datepicker__navigation {
            top: 8px;
        }

        .react-datepicker__month {
            margin: 8px 0 11px 0;
        }

        .react-datepicker__header {
            background-color: map-get($color, ace-white);
            padding-top: 8px;
            border: 0;
        }
    }
}

.ace-c-date-picker__popper {
    box-sizing: border-box;
    margin-top: 0 !important;

    &--input-style {
        border-radius: 0 0 30px 30px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.22);
        z-index: 2;
        top: 30px !important;
        transform: none !important;
        will-change: unset !important;
        background-color: map-get($color, ace-white);
        width: 100%;
    }

    &--button-style {
        border-radius: 0;
        position: relative !important;
        box-shadow: none;
        transform: translate3d(0);
        top: -42px !important;
        background-color: map-get($color, ace-white);
    }
}

.react-datepicker__day {
    width: 30px;
    height: 20px;
    border-radius: 0;
    background-color: map-get($color, ace-white);
    color: map-get($color, ace-bodycopies);
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;

    &:hover {
        background-color: map-get($color, ace-white);
    }

    &--selected {
        font-weight: bold;
    }

    &--today {
        font-weight: 300;
    }

    &--outside-month {
        color: map-get($color, ace-grey-medium);
    }

    &--disabled {
        color: map-get($color, ace-grey-medium);
    }
}

.react-datepicker__day-name {
    width: 30px;
    height: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: map-get($color, ace-bodycopies);
    margin: 0;
}

%arrow-icon {
    &::after {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../assets/icons/direction-arrow.svg');
        background-position: center;
        background-size: 24px 24px;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker-year-header {
    padding-top: 40px !important;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
    display: none;
}
