@import '../styles/config';
@import '../styles/mixins';
@import '../styles/includes';

.ace-c-invoice-submission-archive-view {
    margin-top: 82px;
    margin-bottom: 84px;
    position: relative;

    @include responsive-desktop-up() {
        margin-top: 102px;
    }

    @include responsive-desktop-xxl-up() {
        margin-top: 150px;
    }
}

.ace-c-invoice-submission-archive-view__export-button-wrapper {
    margin-top: 50px;
}

.ace-c-invoice-submission-archive-view__button-description {
    @extend %ace-copy-s;

    text-align: center;
    margin-bottom: 15px;
}

.ace-c-invoice-submission-archive-view__header {
    margin-bottom: 90px;
}

.ace-c-invoice-submission-archive-view__table-header {
    padding-top: 40px;
    padding-bottom: 13px;
    margin-bottom: 5px;
    position: sticky;
    z-index: 3;
    background: map-get($color, ace-white);
    top: 70px;
    width: 100%;
}

.ace-c-invoice-submission-archive-view__button-container {
    width: 100%;
}

.ace-c-invoice-submission-archive-view__tooltip-wrapper {
    position: relative;
}

.ace-c-invoice-submission-archive-view__tooltip-message {
    @extend %ace-copy-s;

    overflow-wrap: anywhere;
    display: none;
    position: absolute;
    bottom: 110%;
    left: 0;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    z-index: 2;
}

.ace-c-invoice-submission-archive-view__tooltip-button.ace-hoc-button--is-disabled {
    &:hover + .ace-c-invoice-submission-archive-view__tooltip-message {
        display: block;
    }

    label {
        cursor: help;
    }
}

.ace-c-invoice-submission-archive-view__download-modal {
    height: unset;

    p {
        margin-bottom: 40px;
    }

    .ace-c-invoice-submission-archive-view__download-modal__button {
        width: 300px;
        margin: 0 auto;
    }
}
