@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-c-filter-order__option {
    @extend %ace-input-main-font;

    color: map_get($color, ace-bodycopies);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;

    &:nth-of-type(even) {
        background-color: map_get($color, ace-grey-superlight);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &[aria-selected='true'] {
        font-weight: bold;
    }
}

.ace-c-filter-order__icon-wrapper {
    display: flex;
}
