@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';

.view-indicator__container {
    @include flex-center();

    flex-direction: row;
    margin-bottom: 76px;

    @include responsive-desktop-xxl-up() {
        margin-bottom: 96px;
    }
}

.view-indicator__wrapper {
    position: relative;
}

.view-indicator__indicator {
    line-height: 29px;
    width: 30px;
    font-size: 20px;
    color: map-get($color, ace-white);
    border-radius: 22px;
    font-weight: bold;
    text-align: center;
    background-color: map-get($color, ace-grey-light);

    &--active {
        background-color: map-get($color, ace-grey-medium);
    }
}

.view-indicator__text {
    @extend %ace-copy-s;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;

    @include responsive-desktop-wide-up() {
        bottom: -30px;
    }

    @include responsive-desktop-xxl-up() {
        bottom: -38px;
    }
}

.view-indicator__separator {
    width: 210px;
    height: 2px;
    background-color: map-get($color, ace-grey-light);
    display: inline-block;
}

.view-indicator__invoice-requested-wrapper {
    @include flex-center();

    width: 160px;
    height: 30px;
    border-radius: 15px;
    border: solid 2px map-get($color, ace-grey-medium);
}

.view-indicator__invoice-requested-text {
    @extend %ace-copy-s;

    font-weight: bold;
    color: map-get($color, ace-grey-medium);
}
