@import '../styles/config';
@import '../styles/ace-base';
@import '../styles/includes';
@import '../styles/grid-variables';
@import '../styles/mixins';

.ace-main-service__wrapper {
    margin-bottom: 100px;
}

.ace-main-service__text-wrapper {
    display: inline-flex;
    align-items: flex-start;
    font-size: 0;
}

.ace-main-service__icon-wrapper {
    margin-right: 10px;
}

.ace-main-service__tariff-wrapper {
    display: flex;
    margin-top: 10px;
}

.ace-form-map__overlay {
    @extend %overlay;

    z-index: 9999;
}

.ace-form-map__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    background-color: map-get($color, ace-white);

    @include responsive-tablet-landscape-up() {
        max-width: map-get($max-grid-width, tablet-portrait);
    }

    @include responsive-desktop-up() {
        max-width: map-get($max-grid-width, tablet-landscape);
    }

    @include responsive-desktop-wide-up() {
        max-width: map-get($max-grid-width, desktop);
    }

    @include responsive-desktop-xxl-up() {
        max-width: map-get($max-grid-width, desktop-wide);
    }
}

.ace-form-map__container {
    height: 317px;
    position: relative;

    &--full-screen {
        height: 50vh;
        z-index: 10000;
    }
}

.ace-form-map__extend-btn {
    @include flex-center();

    position: absolute;
    top: 8px;
    right: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
    cursor: pointer;
}

.ace-form-map__address-wrapper {
    vertical-align: top;
    margin-top: 32px;

    &--full-screen {
        display: none;
    }
}

.ace-form-map__button-wrapper {
    display: none;

    &--full-screen {
        display: block;
    }
}

.ace-c-billing-radio-button__tooltip-message {
    @extend %ace-copy-s;

    overflow-wrap: anywhere;
    display: none;
    position: absolute;
    bottom: 110%;
    left: 0;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: map-get($color, ace-white);
    z-index: 2;
}

.service-form__text-area-wrapper {
    padding-top: 50px;

    @include responsive-desktop-wide-up() {
        height: 100%;
        padding-top: 32px;
    }
}

.service-form__text-area-container {
    height: 135px;

    @include responsive-desktop-wide-up() {
        height: 100%;
    }

    .ace-c-text-area {
        height: 100%;
    }
}

.ace-main-service__toast-message {
    width: 352px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(-352px - 16px);
}

.internal-invoice__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
