@import '../../styles/config';
@import '../../styles/functions';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-search-input__wrapper {
    position: relative;
    width: 100%;
}

.ace-c-search-input {
    @extend %ace-input-field;
    @extend %ace-input-main-font;

    width: 100%;
    padding-right: 110px;
    color: map-get($color, ace-bodycopies);

    &:focus {
        border: solid $border map-get($color, ace-bodycopies);
    }

    &--locked {
        pointer-events: none;
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }

    &--error {
        border: solid $border map-get($color, ace-red);
    }

    &--disabled {
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
    }
}

.ace-c-search-input__icon-wrapper {
    @include flex-center();

    position: absolute;
    top: 5px;
    right: 5px;
    background-color: map-get($color, ace-red);
    width: 100px;
    height: 50px;
    border-radius: 99px;
    border: solid $border map-get($color, ace-red);
    z-index: 100;
    cursor: pointer;

    &--disabled {
        cursor: auto;
        background-color: map-get($color, ace-grey-medium);
        border: solid $border map-get($color, ace-grey-medium);
        box-shadow: none;
    }

    &--locked {
        cursor: auto;
        background-color: map-get($color, ace-grey-medium);
        border: solid $border map-get($color, ace-grey-medium);
        box-shadow: none;
    }
}

.ace-c-search-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    position: absolute;
    color: map-get($color, ace-grey-medium);

    &--locked {
        color: map-get($color, ace-grey-dark);
    }
}

.ace-c-search-input--focused ~ .ace-c-search-input__label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-placeholder-position;

    color: map-get($color, ace-grey-medium);
}

.ace-c-search-input__error-label {
    @extend %ace-input-placeholder-font;
    @extend %ace-input-error-label;
}
