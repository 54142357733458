@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-time-input__part {
    &--error {
        border: red solid;
    }
}

.ace-c-time-input {
    display: flex;
    padding-left: 26px;
    align-items: center;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid $border map-get($color, ace-grey-medium);
    background-color: map-get($color, ace-white);

    &--error {
        border: solid $border #e2001a;
    }

    &--disabled {
        border: solid $border map-get($color, ace-grey-light);
        box-shadow: none;
        pointer-events: none;
    }

    &--focused {
        border: solid $border map-get($color, ace-bodycopies);
    }
}

.ace-c-time-input__label {
    @extend %ace-input-main-font;

    position: absolute;
    top: 16px;
    left: 27px;
    color: map-get($color, ace-grey-dark);
    transition: all 0.3s;

    @include responsive-desktop-wide-up() {
        top: 14px;
    }

    &--focused {
        @extend %ace-input-placeholder-font;

        top: -28px;
        left: 27px;
        color: map-get($color, ace-grey-medium);

        @include responsive-desktop-wide-up() {
            top: -32px;
        }
    }

    &--locked {
        color: map-get($color, ace-grey-dark);
    }
}

.ace-c-time-input__input {
    @extend %ace-input-main-font;

    width: 22px;
    padding: 0;
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
}

.ace-c-time-input__separator {
    @extend %ace-input-main-font;

    &--hidden {
        display: none;
    }
}

.ace-c-time-input__error-message {
    color: #e2001a;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: -0.47px;
    margin-top: 5px;
}
