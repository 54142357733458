@import '../../../styles/includes';

.ace-c-summary-data-cell {
    @extend %ace-copy-s;

    word-break: break-word;
    line-height: 28px;

    @include responsive-desktop-up() {
        line-height: 30px;
    }

    @include responsive-desktop-wide-up() {
        line-height: 30px;
    }

    @include responsive-desktop-xxl-up() {
        line-height: 30px;
    }
}
