@import '../../styles/mixins';
@import '../../styles/config';

.ace-c-checkbox {
    @include flex-center();

    width: 40px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.15);
    border: solid 2px #e2001a;
    background-color: #fff;
    cursor: pointer;

    &--disabled {
        border: solid 2px #98a09c;
        cursor: default;
        pointer-events: none;
        box-shadow: none;
    }

    &--icon-small {
        flex: 0 0 25px;
        height: 25px;
        margin-right: 20px;
    }

    &--secondary {
        width: 25px;
        height: 25px;
        border: solid 2px map-get($color, ace-red);
    }
}
