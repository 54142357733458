@import './config';

.edit-page__form-container {
    padding: 70px 0;
    margin-bottom: 100px;
    position: relative;
    background-color: map-get($color, ace-grey-background);
}

.edit-page__shadow-wrapper {
    position: absolute;
    height: 30px;
    width: 100%;

    &--bottom {
        bottom: -30px;
        transform: rotate(180deg);
    }

    &--top {
        top: -30px;
    }
}

.edit-page__shadow {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50% / 30px;
    box-shadow: map-get($color, ace-grey) 0 5px 10px;
    clip: rect(30px, auto, 50px, 0);
}

.edit-page-form-container__title {
    margin-bottom: 32px;
}

.disable-element {
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
}

.no-scroll {
    position: fixed;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.ace-wrap-word {
    overflow-wrap: anywhere;
}

.ace-flex-cell {
    display: flex;

    &--center {
        justify-content: center;
        align-items: center;
    }
}
