@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';

.ace-c-error-message {
    @extend %ace-input-placeholder-font;

    margin-top: 10px;
    padding-left: 20px;
    color: map-get($color, ace-red);
}
