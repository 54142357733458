@import '../../../styles/includes';
@import '../../../styles/grid-variables';

.ace-c-member-detail-row {
    display: flex;

    &__label {
        @extend %ace-h6;

        flex: 0 0 135px;
        margin-right: map-get($gutter-size, tablet-landscape);

        @include responsive-desktop-up() {
            flex: 0 0 160px;
            margin-right: map-get($gutter-size, desktop);
        }

        @include responsive-desktop-wide-up() {
            flex: 0 0 200px;
            margin-right: map-get($gutter-size, desktop-wide);
        }

        @include responsive-desktop-xxl-up() {
            flex: 0 0 300px;
            margin-right: map-get($gutter-size, desktop-xxl);
        }
    }
}
