@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-c-table-cell {
    display: flex;
    align-items: center;

    &--right {
        justify-content: flex-end;
    }

    &--right-margin {
        justify-content: flex-end;
        margin-right: -50px;
    }

    &--center {
        justify-content: center;
    }

    &--space-around {
        justify-content: space-around;
    }
}
