@import '../../styles/includes';

.ace-driver-assignment-map__container {
    height: 400px;
    position: relative;

    &--full-screen {
        height: 50vh;
        z-index: 10000;
    }
}

.ace-driver-assignment-map__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    background-color: map-get($color, ace-white);
    z-index: 1000;

    @include responsive-tablet-landscape-up() {
        width: map-get($max-grid-width, tablet-portrait);
    }

    @include responsive-desktop-up() {
        width: map-get($max-grid-width, tablet-landscape);
    }

    @include responsive-desktop-wide-up() {
        width: map-get($max-grid-width, desktop);
    }

    @include responsive-desktop-xxl-up() {
        width: map-get($max-grid-width, desktop-wide);
    }
}
