@import '../styles/config';
@import '../styles/includes';

.ace-c-invoice-submissions-view__modal-buttons__container {
    width: 80%;
    margin: 0 auto;

    & > div {
        width: 100%;
    }
}

.ace-c-invoice-submission-view__pre-check-modal__content {
    display: flex;
}

.ace-c-pre-check-modal {
    height: auto;
    overflow-y: unset;
    min-width: 925px;
}

.ace-c-invoice-submission-view__pre-check-modal__content__main {
    width: 100%;
    margin-right: 5px;
}

.ace-c-invoice-submissions-view__notification-container {
    @include flex-center();

    height: 112px;
}

.ace-c-invoice-submission-view__pre-check-modal__content__navigation {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 5%;
}

.ace-c-invoice-submission-view__pre-check-modal__content__navigation__link {
    border-radius: 50%;
    background-color: map-get($color, ace-grey-light);
    width: 15px;
    height: 15px;
    margin-bottom: 20px;
    cursor: pointer;
}

.ace-c-invoice-submission-view__pre-check-modal__content__navigation__link--active {
    background-color: map-get($color, ace-grey-medium);
}

.ace-c-invoice-submission-view__pre-check-modal-button {
    width: 40%;
    margin: 0 auto;
}

.ace-c-manual-case-wrapper {
    position: relative;
}

.ace-c-manual-case-button {
    display: inline-block;
    position: absolute;
    right: 15px;
    z-index: 101;
    top: 90px;

    &:hover {
        color: map-get($color, ace-red-dark);
    }

    @media screen and (max-width: 1200px) {
        top: 50px;
    }
}

@media screen and (min-width: 1700px) {
    .ace-c-invoice-submission-view__pre-check-modal__content__navigation {
        position: absolute;
        right: -150px;
    }

    .ace-c-invoice-submission-view__pre-check-modal-button {
        width: 45%;
    }
}
