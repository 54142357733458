@import '../../../styles/config';
@import '../../../styles/mixins';
@import '../../../styles/includes';
@import '../../../styles/grid-variables';

.ace-invoice-submission-archive-row-detail__table-body {
    background-color: map-get($color, ace-grey-background);
}

.ace-invoice-submission-archive-row-detail__table-row {
    grid-template-rows: repeat(4, auto);

    &--border {
        border-top: 1px solid rgba(84, 97, 90, 0.1);
    }
}

.ace-invoice-submission-archive-row-detail__cell {
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative;

    &--light {
        background-color: map-get($color, ace-grey-superlight);
    }
}

.ace-invoice-submission-archive-row-detail__options-column {
    grid-column-start: 12;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 8px;

    @include responsive-desktop-xxl-up() {
        padding-right: 42px;
    }

    > .ace-c-hover-icon {
        margin-bottom: 30px;
    }

    > .ace-c-hover-icon:last-child {
        margin-bottom: 0;
    }
}

.ace-invoice-submission-archive-row-detail__single-service {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ace-invoice-submission-archive-row-detail__time-cell {
    display: block;
}

.ace-invoice-submission-archive-row-detail__time-text {
    @extend %ace-copy-s;

    margin-bottom: 8px;
}

.ace-invoice-submission-archive-row-detail__flex-start {
    align-items: flex-start;
}

.ace-invoice-submission-archive-row-detail__time-text-title {
    width: 60px;
    display: inline-block;
}
