@import '../../styles/config';
@import '../../styles/mixins';

.ace-c-scroll-to-top__wrapper {
    position: fixed;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.ace-c-scroll-to-top__container {
    position: relative;
}

.ace-c-scroll-to-top__button {
    @include flex-center();

    position: absolute;
    background-color: map-get($color, ace-white);
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
}
