@import '../../../styles/config';
@import '../../../styles/ace-base';
@import '../../../styles/includes';
@import '../../../styles/mixins';

.ace-c-table-row {
    &--md {
        height: 85px;

        &:hover {
            cursor: pointer;
        }

        > * {
            align-self: center;
        }
    }

    &--shadow {
        &::before {
            @extend %common-shadow;

            top: -30px;
        }

        &::after {
            @extend %common-shadow;

            transform: rotate(180deg);
            bottom: -30px;
        }

        &:hover {
            &::before {
                box-shadow: none;
            }

            &::after {
                box-shadow: none;
            }
        }
    }
}
