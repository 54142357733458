@import '../styles/config';
@import '../styles/ace-base';
@import '../styles/mixins';

.invoice-submission-wrapper-view {
    padding-top: 40px;
    margin-bottom: 20px;

    @include responsive-desktop-xxl-up() {
        padding-top: 60px;
    }
}

.invoice-submission-wrapper-view__title {
    text-align: center;
    margin-bottom: 33px;
}

.invoice-submission-wrapper-view__no-invoice-submission {
    text-align: center;
    margin: 30vh 0;
}

.ace-edit__toggle-wrapper {
    position: relative;
}

.ace-edit__toggle-container {
    position: absolute;
    right: 28px;
    top: -110px;

    @include responsive-desktop-xxl-up() {
        top: -130px;
        right: 38px;
    }
}

.ace-edit__tooltip-container {
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.ace-edit__message-exit-btn {
    max-width: 350px;
    margin: 50px auto 0;
}
