.gm-fullscreen-control {
    display: none;
}

.gm-svpc {
    display: none;
}

.gm-style-mtc {
    display: none;
}

.gm-style-cc {
    display: none;
}

.gm-style a {
    display: none !important;
}
