@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-checkbox-select__container {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.ace-c-checkbox-select__wrapper {
    &--disabled {
        user-select: none;
    }
}

.ace-c-checkbox-select__label {
    @extend %ace-input-main-font;

    color: map_get($color, ace-grey-dark);
    background-color: map_get($color, ace-white);
    height: 35px;
    user-select: none;
    font-size: 16px;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
    padding: 10px 0;
}

.ace-c-checkbox-select__label-text--active {
    text-decoration: underline;
}

.ace-c-checkbox-select__label-icon {
    padding: 0 5px;

    &--expanded {
        transform: rotate(180deg);
    }
}

.ace-c-checkbox-select__option {
    @extend %ace-input-main-font;

    display: inline-flex;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    color: map_get($color, ace-bodycopies);
    cursor: pointer;

    &[aria-selected='true'] {
        font-weight: bold;
    }

    &--disabled {
        cursor: default;
    }
}

.ace-c-filter-select__wrapper {
    max-height: 350px;
    overflow-y: auto;
}
