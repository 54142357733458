@import '../../styles/mixins';
@import '../../styles/includes';

.ace-validation-message__icon-wrapper {
    @include flex-center();

    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 10px;
}

.ace-validation-message__icon-checkmark {
    width: 21px;
    height: 16px;
}

.ace-validation-message__notification {
    @extend %ace-copy-m;

    line-height: 27px;
    font-size: 20px;

    &--red {
        color: map-get($color, ace-red);
    }
}
