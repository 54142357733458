@import '../styles/config';
@import '../styles/ace-base';
@import '../styles/includes';

.ace-file-upload__container {
    &--error {
        @extend %error-container;
    }
}

.ace-file-upload__wrapper {
    height: 304px;
    padding: 10px;
    background-color: map-get($color, ace-white);
    position: relative;
}

.ace-file-upload {
    @include flex-center();

    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px dashed map-get($color, ace-grey-dark);
    border-image-source: url('../assets/images/border.png');
    border-image-slice: 1;
    border-image-repeat: round;

    &--is-dragging-over {
        opacity: 0.1;
    }

    &--has-files {
        background-size: 0;
        background-color: rgba(255, 255, 255, 0.6);
    }
}

.ace-file-upload__drop-overlay {
    display: none;

    &--is-dragging-over {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }
}

.ace-file-upload__label {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &--is-dragging-over {
        opacity: 0;
    }
}

.ace-file-upload__input {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;

    &--is-dragging-over {
        display: block;
        opacity: 0;
        z-index: 40;
    }
}

.ace-file-upload__button-wrapper {
    position: relative;
    cursor: pointer;

    @include responsive-tablet-landscape-up() {
        width: 350px;
    }

    @include responsive-desktop-wide-up() {
        width: 370px;
    }
}

.ace-file-upload__preview {
    &--is-dragging-over {
        opacity: 0;
        z-index: 30;
    }
}

.ace-file-upload__preview-wrapper {
    @include flex-space-between();

    position: relative;
    margin-bottom: 30px;
}

.ace-file-upload__preview-img-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.ace-file-upload__preview-img {
    display: inline-block;
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    overflow: hidden;
    margin-right: 10px;
}

.ace-file-upload__preview-name {
    word-break: break-all;
}

.file-upload__remove-item-icon {
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-position: center;
    background-size: 36px;
    background-color: #fff;
    right: 3px;
    top: 3px;
    -webkit-box-shadow: 3px 2px 10px -5px rgba(0, 0, 0, 0.85);
    box-shadow: 3px 2px 10px -5px rgba(0, 0, 0, 0.85);
    cursor: pointer;

    &:hover {
        background-color: map-get($color, ace-grey-light);
    }
}

.hide-element {
    display: none;
}
