@import '../../styles/config';
@import '../../styles/mixins';
@import '../../styles/includes';

.ace-manage-users-popups__margin-bottom {
    margin-bottom: 82px;
}

.ace-manage-users-popups__padding-bottom {
    height: 50px;
}
