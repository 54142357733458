@import 'mixins';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,700&display=swap');
@import 'grid-variables';

$logo-box-height: 100px;
$logo-box-angle-width: 34px;

.ace-h1 {
    font-size: 32px;
    line-height: 43px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 46px;
        line-height: 62px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 56px;
        line-height: 76px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 62px;
        line-height: 84px;
    }
}

.ace-h2 {
    font-size: 30px;
    line-height: 41px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 42px;
        line-height: 57px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 50px;
        line-height: 68px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 56px;
        line-height: 76px;
    }
}

%ace-h3 {
    font-size: 26px;
    line-height: 36px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 36px;
        line-height: 49px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 42px;
        line-height: 57px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 50px;
        line-height: 68px;
    }
}

.ace-h3 {
    @extend %ace-h3;

    &--center {
        text-align: center;
    }
}

.ace-h4,
%ace-h4 {
    font-size: 24px;
    line-height: 33px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 30px;
        line-height: 41px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 36px;
        line-height: 49px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 42px;
        line-height: 57px;
    }
}

.ace-h5,
%ace-h5 {
    font-size: 20px;
    line-height: 27px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 24px;
        line-height: 33px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 28px;
        line-height: 38px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 36px;
        line-height: 49px;
    }
}

.ace-h6,
%ace-h6 {
    font-size: 18px;
    line-height: 24px;
    color: map-get($color, ace-headlines);
    letter-spacing: -0.25px;

    @include responsive-desktop-up() {
        font-size: 20px;
        line-height: 27px;
    }

    @include responsive-desktop-wide-up() {
        font-size: 22px;
        line-height: 30px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 28px;
        line-height: 38px;
    }
}

.ace-copy-m,
%ace-copy-m {
    font-size: 18px;
    line-height: 28px;
    color: map-get($color, ace-bodycopies);
    font-weight: 300;
    letter-spacing: -0.25px;

    @include responsive-desktop-wide-up() {
        font-size: 20px;
        line-height: 30px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 22px;
        line-height: 32px;
    }
}

.ace-copy-m {
    &--center {
        text-align: center;
    }
}

.ace-link-m,
%ace-link-m {
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    font-weight: 300;
    letter-spacing: -0.25px;
    cursor: pointer;
    color: map-get($color, ace-red);
    text-transform: uppercase;

    @include responsive-desktop-wide-up() {
        font-size: 20px;
        line-height: 27px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 22px;
        line-height: 32px;
    }
}

.ace-link-m {
    &:hover {
        text-decoration: underline;
    }

    &--small {
        text-transform: lowercase;
    }

    &--inactive {
        color: map-get($color, ace-grey-medium);
    }
}

.ace-copy-s,
%ace-copy-s {
    font-size: 16px;
    line-height: 21px;
    color: map-get($color, ace-bodycopies);
    font-weight: 300;
    letter-spacing: -0.25px;

    @include responsive-desktop-wide-up() {
        font-size: 18px;
        line-height: 28px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 20px;
        line-height: 30px;
    }
}

.ace-copy-s {
    &--grey {
        color: map-get($color, ace-grey-dark);
    }

    &--underlined {
        text-decoration: underline;
    }

    &--bold {
        font-weight: bold;
    }
}

.ace-link-s {
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    font-weight: 300;
    letter-spacing: -0.25px;
    cursor: pointer;
    color: map-get($color, ace-red);
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }

    &--small {
        text-transform: lowercase;
    }

    &--inactive {
        color: map-get($color, ace-grey-medium);
    }

    @include responsive-desktop-wide-up() {
        font-size: 18px;
        line-height: 28px;
    }

    @include responsive-desktop-xxl-up() {
        font-size: 20px;
        line-height: 30px;
    }
}

%ace-input-field {
    height: 60px;
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid $border map-get($color, ace-grey-medium);
    background-color: map-get($color, ace-white);
    padding-left: 26px;
}

%ace-input-main-font {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: -0.25px;

    @include responsive-desktop-wide-up() {
        font-size: 20px;
        line-height: 27px;
    }
}

%ace-input-main-font-md {
    font-size: 14px;
    line-height: 22px;
    font-weight: 200;
    letter-spacing: -0.25px;

    @include responsive-desktop-wide-up() {
        font-size: 16px;
        line-height: 24px;
    }
}

%ace-input-placeholder-font {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: -0.47px;

    @include responsive-desktop-wide-up() {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
    }
}

%ace-input-placeholder-position {
    top: -28px;
    left: 27px;

    @include responsive-desktop-wide-up() {
        top: -32px;
    }
}

%ace-input-error-label {
    color: map-get($color, ace-red);
    padding-left: 30px;
    margin-top: 5px;
}

%error-container {
    border: solid $border map-get($color, ace-red);
    border-radius: 30px;
    background-color: #fff6f7;
}

.ace-error__wrapper {
    &--errors {
        @extend %error-container;

        padding: 35px 20px 35px 20px;
    }
}

.ace-bottom-margin {
    &--sm {
        margin-bottom: 20px;
    }

    &--smd {
        margin-bottom: 30px;
    }

    &--xmd {
        margin-bottom: 40px;
    }

    &--md {
        margin-bottom: 50px;
    }

    &--lg {
        margin-bottom: 64px;
    }

    &--xl {
        margin-bottom: 96px;
    }

    &--xxl {
        margin-bottom: 112px;
    }
}

%common-shadow {
    position: absolute;
    height: 30px;
    width: 100%;
    content: '';
    left: 0;
    background-color: transparent;
    border-radius: 50% / 30px;
    box-shadow: map-get($color, ace-grey) 0 5px 10px;
    clip: rect(30px, auto, 50px, 0);
    pointer-events: none;
    z-index: 2;
}

%logo-shape {
    display: flex;
    align-items: center;
    height: $logo-box-height;
    background-color: map-get($color, ace-red);
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 17px;
    z-index: 151;

    &::after {
        content: '';
        position: absolute;
        right: -$logo-box-angle-width;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $logo-box-height 34px 0 0;
        border-color: map-get($color, ace-red) transparent transparent transparent;
    }
}

%overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}
