@import '../../styles/config';
@import '../../styles/ace-base';
@import '../../styles/includes';
@import '../../styles/mixins';

.ace-c-button-primary {
    @include flex-center();

    @extend %ace-input-main-font;

    width: 100%;
    height: 60px;
    border-radius: 99px;
    box-shadow: 1px 1px 9px 0 rgba(0, 0, 0, 0.22);
    background-color: map-get($color, ace-red);
    color: map-get($color, ace-white);
    cursor: pointer;
    user-select: none;
    position: relative;
    letter-spacing: -0.25px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;

    &--has-icon {
        justify-content: space-between;
        padding-left: 32px;
        padding-right: 59px;
    }

    &:active {
        background-color: map-get($color, ace-red-dark);
    }

    &--is-disabled {
        pointer-events: none;
        background-color: map-get($color, ace-grey-medium);
        box-shadow: none;
    }

    &--md,
    &--md__hoc-inner {
        @extend %ace-input-main-font-md;

        height: 40px;
    }
}

.ace-c-button-primary__icon-wrapper {
    position: absolute;
    right: 15px;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
}
