@import '../styles/config';
@import '../styles/ace-base';
@import '../styles/mixins';
@import '../styles/includes';
@import '../styles/grid-variables';

.overview-container {
    display: flex;
    align-items: flex-start;
}

.overview-data {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;

    &--first {
        padding-top: 0;
    }

    &--grey {
        background-color: map-get($color, ace-grey-background);
    }

    &--is-transparent {
        opacity: 0.3;
    }
}

.overview-data__content {
    position: relative;

    > div {
        margin-bottom: 30px;
    }

    > div:last-child {
        margin-bottom: 0;
    }

    &--block {
        display: block;
    }
}

.overview-data__title {
    @include flex-space-between();

    margin-bottom: 32px;
    padding-right: 6px;

    @include responsive-desktop-up() {
        padding-right: 13px;
    }

    &--first {
        @include responsive-desktop-xxl-up() {
            margin-bottom: 46px;
        }
    }
}

.overview-data__column-title {
    flex: 0 0 135px;
    margin-right: map-get($gutter-size, tablet-landscape);

    @include responsive-desktop-up() {
        flex: 0 0 160px;
        margin-right: map-get($gutter-size, desktop);
    }

    @include responsive-desktop-wide-up() {
        flex: 0 0 200px;
        margin-right: map-get($gutter-size, desktop-wide);
    }

    @include responsive-desktop-xxl-up() {
        flex: 0 0 300px;
        margin-right: map-get($gutter-size, desktop-xxl);
    }
}

.overview-data__column-text {
    @extend %ace-copy-s;

    word-break: break-word;
    line-height: 28px;

    @include responsive-desktop-up() {
        line-height: 30px;
    }

    @include responsive-desktop-wide-up() {
        line-height: 30px;
    }

    @include responsive-desktop-xxl-up() {
        line-height: 30px;
    }
}

.row-flex {
    display: flex;
}

.overview-data-attachments {
    margin-top: 30px;
}

.overview-data-attachments__content {
    display: flex;
    flex-wrap: wrap;
}

.overview-data-attachments__content-item {
    display: flex;
    align-items: center;
    margin-right: 28px;
    margin-top: 19px;
}

.overview-data-attachments__content-icon-wrapper {
    margin-right: 10px;
    overflow: hidden;
}

.overview-data-attachments__content-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
}

.overview-data-attachments__content-text {
    @extend %ace-copy-s;

    word-break: break-all;
}

// PRICE OVERVIEW SECTION

.overview-price__wrapper {
    position: sticky;
    top: map-get($app-layout, header-height) + 149px;
    z-index: 2;
}

.overview-price {
    border-radius: 0 30px 30px 0;
    background-color: #f6f7f6;
    padding: 20px 30px;
    width: calc(100% - 30px);

    &--is-not-visible {
        display: none;
    }

    @include responsive-desktop-xxl-up() {
        width: calc(100% - 40px);
    }
}

.overview-price__left-shadow {
    left: -30px;
    position: absolute;
    height: 100%;
    top: 0;
    width: 30px;

    &--before {
        position: absolute;
        left: 15px;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: transparent;
        border-radius: 50% / 50%;
        box-shadow: map-get($color, ace-grey) 0 5px 15px;
        clip: rect(0, 45px, auto, 15px);
    }
}

.overview-price__date {
    @extend %ace-copy-s;

    line-height: 28px;
}

// .overview-price__member-info {
//    margin-top: 20px;
//    margin-bottom: 20px;
//
//    &-title {
//        @extend %ace-copy-s;
//
//        line-height: 28px;
//        font-weight: bold;
//    }
//
//    &-name {
//        @extend %ace-copy-s;
//
//        line-height: 28px;
//    }
//
//    &-other {
//        @extend %overview-price-text-small;
//    }
// }

.overview-price__services-info-title {
    @extend %ace-copy-s;

    line-height: 28px;
    font-weight: bold;
}

.overview-price__services-info-list-item {
    @include flex-space-between();
}

.overview-price__services-info-text {
    @extend %ace-copy-s;

    line-height: 28px;

    &--caped-price {
        color: #e2001a;
        font-weight: bold;
    }
}

.overview-price__services-info-net-price {
    @extend %ace-copy-s;

    font-weight: bold;
    line-height: 28px;
}

.overview-price__services-info-summary {
    display: flex;
    justify-content: space-between;
}

.overview-price__internal-invoice {
    padding: 18px 20px;
    background: map-get($color, ace-white);
    display: flex;
    flex-direction: column;
    margin: 20px -20px;
}

.overview-price__internal-invoice-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}

.overview-price__internal-invoice-text {
    @extend %ace-copy-s;

    line-height: 28px;
    font-weight: bold;
}

.caped-price-tooltip {
    align-self: center;
    height: 20px;

    p {
        width: 275px;
    }
}

.overview-price__internal-invoice-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;

    &--caped-price {
        height: 20px;
        margin-left: unset;
        margin-right: 3px;
    }
}

.overview-price__internal-invoice-checkbox-container {
    @include flex-center();

    flex: 0 0 35%;
}

.overview-price__internal-invoice-checkbox {
    appearance: none;
    width: 40px;
    height: 40px;
    background: map-get($color, ace-grey);
}

.overview-price__member-certificate {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.overview-price__member-certificate-text {
    @extend %ace-copy-s;
}

.overview-price__member-certificate-checkbox-container {
    margin-right: 10px;
}

.overview-price__member-certificate-checkbox {
    appearance: none;
    width: 40px;
    height: 40px;
    background: map-get($color, ace-grey);
}

.overview-price__submit-button-container {
    margin-top: 20px;
}

.download-proof-button-container {
    margin-top: 20px;

    .ace-c-button-secondary__content {
        font-size: 16px;
    }
}

.overview-price__back-to-application-link-wrapper {
    margin-top: 22px;
    text-align: center;
    cursor: pointer;
}

.overview-price__save-button-container {
    margin-top: 70px;

    &--is-transparent {
        opacity: 0.3;
        cursor: default;
    }
}

.overview-price__add-additional {
    text-transform: uppercase;
    color: map-get($color, ace-red);
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
}

.overview-capped-price-container {
    display: flex;
}

.overview-capped-price__tooltip {
    align-self: center;
}

.overview-validation-list {
    margin-left: 40px;
}
